import ResourceTable from "./ResourceTable";

function ManageResource() {
  return (
    <>
      <div className='row col-lg-10'>
        <ResourceTable></ResourceTable>
      </div>
    </>
  );
}

export default ManageResource;
