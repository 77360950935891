//NOYAN UPDATE
import {setPreviewImage, setPreviewResourceData} from '../service/resourceStorage';

export const updatePreviewResourceData = (data) => {

    // return (dispatch) => {
    //     dispatch({
    //         type: 'UPDATE_PREVIEW_RESOURCE_CONTENT',
    //         payload: data,
    //     });
    //
    //     setPreviewResourceData(data); // Save  data in  storage
    // };
   return setPreviewResourceData(data)
};

export const updatePreviewResourceImageData = (img) => {

    // return (dispatch) => {
    //     dispatch({
    //         type: 'UPDATE_PREVIEW_RESOURCE_IMAGE',
    //         payload: img,
    //     });

        setPreviewImage(img); // Save  data in  storage
    // };
};