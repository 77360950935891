//NOYAN new Session
import { getSessionData } from "../service/sessionStorage";

const storedSessionData = getSessionData();

const initialState = {
  isLoggedIn: false,
  user: null,
  roles: [],
  ...storedSessionData, // Load session data from session storage
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_SESSION_DATA":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default sessionReducer;
