import { useState } from "react";

import { FiArrowRight } from "react-icons/fi";
import { createExpert } from "../../../../service/publicServices";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select from "../../components/select/Select";
import CkEditor from "../../components/Editor/CkEditor";
import IndustryList from "../../../../data/search/industries.json";
import CountryList from "../../../../data/search/country.json";
import ExpertiseList from "../../../../data/search/expertise.json";

const MySwal = withReactContent(Swal);

function AddExpert({ props, formStyle, sIndustry, sService, sCountry, cookieCountry, sBusinessSize }) {
  const ExpertiseListVL = ExpertiseList.map(item => ({
    value: item.id,
    label: item.name,
  }));


  const [IsSpinner, setIsSpinner] = useState(false);

  const [image, setImage] = useState(null);
  const [selectedImage, setselectedImage] = useState(null);

  const handleUpload = e => {
    var file = e.target.files;
    const imageUrl = URL.createObjectURL(file[0]);
    setImage(file[0]);
    setselectedImage(imageUrl);
  };

  const [expertName, setExpertName] = useState(null);
  const onChangeExpertName = e => {
    setExpertName(e.target.value);
  };
  const [url, setUrl] = useState(null);
  const onChangeUrl = e => {
    setUrl(e.target.value);
  };
  const [order, setOrder] = useState(null);
  const onChangeOrder = e => {
    setOrder(e.target.value);
  };

  const [designation, setDesignation] = useState(null);
  const onChangeDesignation = e => {
    setDesignation(e.target.value);
  };

  const [about, setAbout] = useState(null);
  const onChangeAbout = e => {
    setAbout(e);
  };

  const [quote, setQuote] = useState(null);
  const onChangeQuote = e => {
    setQuote(e);
  };
  

  const [expertise, setExpertise] = useState(null);
  const onChangeExpertise = selectedOption => {
    setExpertise(selectedOption);
  };

  const [sectors, setSectors] = useState(null);
  const onChangeSectors = selectedOption => {
    setSectors(selectedOption);
  };

  const [country, setCountry] = useState(null);
  const onChangeCountry = selectedOption => {
    setCountry(selectedOption);
  };

  const prepareData = () => {
    const formData = new FormData();
    const data = {
      expert: {
        name: expertName,
        gender:"",
        order:parseInt(order),
        status:1,
        title: designation,
        description: about,
        imageUrl:"",
        quote:quote,
        url:"expert-detail/" + url,
        country: country,
        expertise: expertise,
        sectors: sectors
      }
    }
    data.expert.country = data.expert.country.map(country => ({
      id: country.value,
      name: country.label,
      shortCode:country.shortCode,
    }));
    data.expert.expertise = data.expert.expertise.map(expertise => ({
      id: expertise.value,
      name: expertise.label,
    }));
    data.expert.sectors = data.expert.sectors.map(sectors => ({
      id: sectors.value,
      name: sectors.label,
    }));
    formData.append("request", JSON.stringify(data));
    formData.append("file", image);
    return formData;
  };


  const saveExpert = e => {
    e.preventDefault();
    setIsSpinner(true);
   
    createExpert(prepareData()).then(
      result => {
        setIsSpinner(false);
        //setResult(true);
        if (result.data.code === "1") {
          MySwal.fire({
            confirmButtonColor: "#EC726B",
            title: <strong>Success !</strong>,
            html: <i>{result.data.description}</i>,
            icon: "success",
          });
        } else {
          MySwal.fire({
            confirmButtonColor: "#EC726B",
            title: <strong>Error !</strong>,
            html: <i>{result.data.description}</i>,
            icon: "error",
          });
        }
      },
      error => {
        setIsSpinner(false);
        MySwal.fire({
          confirmButtonColor: "#EC726B",
          title: <strong>Error !</strong>,
          html: <i>{error.data}</i>,
          icon: "error",
        });
      },
    );
    e.target.reset();
  };

  return (
    <form className={`${formStyle} pt-5`} action='' onSubmit={saveExpert}>
      <label className='form-label ps-5'>Image</label>
      <div className='row mb-5 ps-5'>
        {selectedImage &&
          <div className='col-lg-2 '>
            <img src={selectedImage} alt="Profile Photo" width="200" />
          </div>
        }
        <div className='col-lg-8 pt-5'>
          <input className='form-control'
            id='formFileSm'
            type='file'
            name='file'
            placeholder='Upload Image'
            onChange={handleUpload}
            required
          >
          </input>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='form-group'>
            <label className='form-label '>Expert Name</label>
            <input type='text' name='expertName' placeholder='Expert Name' required onChange={onChangeExpertName} />
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='form-group'>
            <label className='form-label '>Title</label>
            <input type='text' name='designation' placeholder='title' required onChange={onChangeDesignation} />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='form-group'>
            <label className='form-label '>url</label>
            <input type='text' name='url' placeholder='url' required onChange={onChangeUrl} />
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='form-group'>
            <label className='form-label '>Order</label>
            <input type='number' name='order' placeholder='order' required onChange={onChangeOrder} />
          </div>
        </div>
      </div>
      <div className='form-group'>
        <label className='form-label '>About</label>
        <CkEditor name='about' onChange={onChangeAbout}></CkEditor>
      </div>
      <div className='form-group'>
        <label className='form-label '>Quote</label>
        <CkEditor name='quote' onChange={onChangeQuote}></CkEditor>
      </div>
      <div className='form-group'>
        <Select pHolder='Expertise' name='expertise' options={ExpertiseListVL} isMulti={true}  onValueChange={onChangeExpertise}></Select>
      </div>
      <div className='form-group'>
        <Select pHolder='Industry / Sector Serve' name='sectors' options={IndustryList}  isMulti={true} onValueChange={onChangeSectors}></Select>
      </div>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='form-group'>
            <Select pHolder='Country Serve' name='country' options={CountryList} isMulti={true}  onValueChange={onChangeCountry}></Select>
          </div>
        </div>
      </div>

      <div className='row ps-5'>
        <div className='form-group  col-xl-4'>
          <button className='btn-default btn-small'>
            Save
            <i className='icon ms-3'>
              {IsSpinner ? (
                <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
              ) : (
                <FiArrowRight />
              )}
            </i>
          </button>
        </div>
        <div className='form-group col-xl-4'>
          <button className='btn-default btn-small'>
            Cancel
            <i className='icon ms-3'>
              {IsSpinner ? (
                <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
              ) : (
                <FiArrowRight />
              )}
            </i>
          </button>
        </div>
        <div className='form-group col-xl-4'>
          <button className='btn-default btn-small '>
            Preview
            <i className='icon ms-3'>
              {IsSpinner ? (
                <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
              ) : (
                <FiArrowRight />
              )}
            </i>
          </button>
        </div>
      </div>
    </form>
  );
}

export default AddExpert;
