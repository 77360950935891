// TODO: FOR SSR, NODE DOES NOT RECOGNIZE WINDOW OBJECT.
// TODO: RUN THIS CODE ONLY IN BROWSER ENVIRONMENT

import isBrowserEnv from "./isBrowserEnv";

if (isBrowserEnv()) {
  window.onload = () => {
    const mainElement = document.querySelector(".vectra-homepage");
    let dataPageValue = null;
    if (mainElement) {
      dataPageValue = mainElement.getAttribute("data-page");
    }

    if (dataPageValue === "vectra-homepage") {
      const getCookie = name => {
        const value = " " + document.cookie;
        const parts = value.split(" " + name + "=");
        return parts.length < 2 ? undefined : parts.pop().split(";").shift();
      };

      const setCookie = function (name, value, expiryDays, domain, path, secure) {
        const exdate = new Date();
        exdate.setHours(exdate.getHours() + (typeof expiryDays !== "number" ? 365 : expiryDays) * 24);
        document.cookie =
          name +
          "=" +
          value +
          ";expires=" +
          exdate.toUTCString() +
          ";path=" +
          (path || "/") +
          (domain ? ";domain=" + domain : "") +
          (secure ? ";secure" : "");
      };

      const $cookiesBanner = document.querySelector(".cookies-eu-banner");
      //const $cookiesBannerButton = $cookiesBanner.querySelector("btn-cookies-allow-all");
      //const $cookiesRejectBannerButton = $cookiesBanner.querySelector("btn-cookies-reject-all");
      const $cookiesBannerButton = $cookiesBanner.querySelector(".btn-cookies-allow-all");
      const $cookiesRejectBannerButton = $cookiesBanner.querySelector(".btn-cookies-reject-all");
      const cookieName = "VECTRA-cookies";
      const hasCookie = getCookie(cookieName);

      if (!hasCookie) {
        $cookiesBanner.classList.remove("hidden");
      }

      $cookiesBannerButton.addEventListener("click", () => {
        var cookiesValues = "CookiesLoaded";
        setCookie(cookieName, cookiesValues);
        $cookiesBanner.remove();
      });

      $cookiesRejectBannerButton.addEventListener("click", () => {
        $cookiesBanner.remove();
      });
    }
  };
}