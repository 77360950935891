import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Row } from "reactstrap";

function AdminHome() {
  return (
    <>
      <div>AdminHome</div>
      <div className='col-lg-12'>
        <Tabs>
          <div className='row row--30 align-items-center '>
            <div className='col-lg-12'>
              <div className='rn-default-tab style-three'>
                <div className='tab-button-panel'>
                  <TabList className='tab-button'>


                    <Tab>
                      <div className='rn-tab-button'>
                        <button>Manage Static Content</button>
                      </div>
                    </Tab>
                  </TabList>
                </div>
                <div className='tab-content-panel'>
                  <TabPanel>
                    <div className='col-lg-12 p-5'>
                      <div className='row align-items-center'>
                        <Row>
                          <a href='#'> Home Page </a>
                        </Row>

                        <Row>
                          <a href='#'> About Us page </a>
                        </Row>

                        <Row>
                          <a href='#'> Services Page </a>
                        </Row>

                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </Tabs>
      </div>
    </>
  );
}

export default AdminHome;
