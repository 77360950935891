import { CHANGE_LANGUAGE } from "../actions/type";

const langReducer = (state = { lang: "en" }, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return { lang: action.paylaod };
    default:
      return state;
  }
};

export default langReducer;
