import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AddExpert from "./AddExpert";
import ManageExpert from "./ManageExpert";

function AdminExperts() {
  return (
    <div className='row'>
      <div className='col-lg-12'>
        <Tabs>
          <div className='row row--30 align-items-center '>
            <div className='col-lg-12'>
              <div className='rn-default-tab style-three'>
                <div className='tab-button-panel'>
                  <TabList className='tab-button'>
                    <Tab>
                      <div className='rn-tab-button'>
                        <button>Manage</button>
                      </div>
                    </Tab>
                    <Tab>
                      <div className='rn-tab-button'>
                        <button> + Add Expert</button>
                      </div>
                    </Tab>
                  </TabList>
                </div>

                <div className='tab-content-panel'>
                  <TabPanel>
                    <div className='col-lg-12 p-5'>
                      <div className='row align-items-center'>
                        <ManageExpert></ManageExpert>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='col-lg-12 '>
                      <div className='row align-items-center'>
                        <div className='col-lg-6 '>
                          <div className='content'>
                            {" "}
                            <AddExpert></AddExpert>
                          </div>
                        </div>
                        <div className='col-lg-5'>{/* ExpertDetailPage gelecek */}</div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </Tabs>
      </div>
    </div>
  );
}

export default AdminExperts;
