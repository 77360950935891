import React, { useState } from "react";
import "./SideNavBar.scss";
import {
  AiOutlineHome,
  AiOutlineTeam,
  AiOutlineSetting,
  AiOutlineShake,
  AiOutlineCaretDown,
  AiOutlineContacts
} from "react-icons/ai";
import { BiCarousel, BiUserCheck, BiCollection } from "react-icons/bi";
import { FaServicestack, FaHandshake } from "react-icons/fa";
import { MdOutlineManageAccounts } from "react-icons/md";
import { Link } from "react-router-dom";
const SideNavBar = () => {
  const [isExpanded, setExpendState] = useState(false);
  const menuItems = [
    {
      text: "Home",
      icon: <AiOutlineHome />,
      url: "/",
    },
    {
      text: "Carousels",
      icon: <BiCarousel />,
      url: "/carousel",
    },
    {
      text: "Services",
      icon: <FaServicestack />,
      url: "/services",
    },
    {
      text: "Resource",
      icon: <BiCollection />,
      url: "/resource",
    },
    {
      text: "Open Positions",
      icon: <FaHandshake />,
      url: "/open-positions",
    },
    {
      text: "Testimonials",
      icon: <BiUserCheck />,
      url: "/testimonials",
    },
    {
      text: "Partners",
      icon: <AiOutlineTeam />,
      url: "/partners",
    },

    {
      text: "Clients",
      icon: <AiOutlineContacts />,
      url: "/Clients",
    },
    // {
    //   text: "Manage Access",
    //   icon: <MdOutlineManageAccounts />,
    //   url: "/manage-access",
    // },
    // {
    //   text: "Settings",
    //   icon: <AiOutlineSetting />,
    //   url: "/settings",
    // },
  ];

  return (
    <div className={isExpanded ? "side-nav-container" : "side-nav-container side-nav-container-NX"}>
      <div className='nav-upper'>
        <div className='nav-heading'>
          {isExpanded && (
            <div className='nav-brand'>
              <img src='/images/logo/vectra-logo.svg' alt='vectra-logo' srcSet='' />
            </div>
          )}

          <button
            className={isExpanded ? "hamburger hamburger-in" : "hamburger hamburger-out"}
            onClick={() => setExpendState(!isExpanded)}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div className='nav-menu'>
          {menuItems.map(({ text, icon, url }) => (
            <Link key={url} className={isExpanded ? "menu-item" : "menu-item menu-item-NX"} to={url}>
              {icon}
              {isExpanded && <p>{text}</p>}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SideNavBar;
