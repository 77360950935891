import { useState, useRef } from "react";
import { FiMenu } from "react-icons/fi";
import "../navbar/navbar.scss";
import useStickyHeader from "../../../../components/header/useStickyHeader";
import { Link } from "react-router-dom";
import "./header.scss";
import { useSelector } from "react-redux";
import { getSessionData } from "../../../../service/sessionStorage";
import { useDispatch } from "react-redux";
import { logout } from "../../../../actions/auth";
import { useNavigate } from "react-router-dom";

const Header = ({ HeaderSTyle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();

  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  let [check] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = ` ${sticky && check ? "sticky" : ""}`;
  const sessionData = useSelector(getSessionData);

  const onLogout = _ => {
    // remove session and clear local storage ( refresh token and acess token )
    dispatch(logout());

    // Re-route to login page
    navigate("/login");
  };

  const onCanvasHandler = () => {
    setOffcanvasShow(prev => !prev);
  };

  return (
    <>
      <header ref={ref} className={`rn-header header-admin-default `}>
        <div className='container position-relative'>
          <div className='row align-items-center row--0'>
            <div className='col-lg-12 col-md-6 col-8 position-static'>
              <div className='header-right'>
                <nav className='mainmenu-nav d-none d-lg-block'>
                  <ul className='mainmenu items '>
                    <li className='has-droupdown '>
                      <div className='item'>
                        <img
                          className='admin-header-avatar'
                          src='https://fffmestb.blob.core.windows.net/tedd/user/user.png'
                          alt='profile '></img>
                        <Link className='admin-header-username' to='#'>
                          {sessionData?.displayName}
                        </Link>
                        <ul className='submenu'>
                          <li className='link-hover'>
                            <Link to='/'>Profile</Link>
                          </li>
                          <li>
                            <button
                              onClick={onLogout}
                              style={{
                                border: "none",
                                width: "100%",
                                fontSize: "1rem",
                                background: "salmon",
                                marginTop: "2rem",
                                color: "white",
                                padding: "1rem 0rem ",
                              }}>
                              logout
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </nav>
                <div className='mobile-menu-bar ml--5 d-block d-lg-none'>
                  <div className='hamberger'>
                    <span className='hamberger-button' onClick={onCanvasHandler}>
                      <FiMenu />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
