//NOYAN UPDATE
import { setSessionData } from "../service/sessionStorage";

export const updateSessionData = data => {
  return dispatch => {
    dispatch({
      type: "UPDATE_SESSION_DATA",
      payload: data,
    });

    setSessionData(data); // Save session data in session storage
  };
};
