import { SET_SELECTED_COUNTRY } from "../actions/type";

const initialState = {
  isCountrySelected: false,
  vectraSiteLocalizable: false, // true for global site.
  country: { fullName: "", shortCode: "en" },
};

const countryReducer = (state = initialState, action) => {
  //

  const { payload } = action;
  switch (action.type) {
    case SET_SELECTED_COUNTRY:
      return {
        isCountrySelected: payload.isCountrySelected,
        vectraSiteLocalizable: payload.vectraSiteLocalizable,
        country: {
          fullName: payload.country.fullName,
          shortCode: payload.country.shortCode,
        },
      };
    default:
      return state;
  }
};

export default countryReducer;
