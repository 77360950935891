import { useState, useEffect, useMemo, useCallback } from "react";
import EditModal from "./EditModal";
import DataTable from "../../components/DataTable/DataTable";
import RowSubComponent from "../../components/TableRowSubComponent/RowSubComponent ";
import { getAllServicesList } from "../../../../service/publicServices";
import EditSubModal from "./EditSubModal";
import AddLangModal from "./AddLangModal";

function ServicesTable() {
  const [showServiceEditModal, setshowServiceEditModal] = useState(false);
  const [showServiceEditChildModal, setshowServiceEditChildModal] = useState(false);
  const [showServiceAddLangdModal, setshowServiceAddLangdModal] = useState(false);
  const [rowLangType, setrowLangType] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [servicesList, setServicesList] = useState([]);
  const [rowCount, setrowCount] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [rowChildData, setRowChildData] = useState(null);
  const [rowUrl, setRowUrl] = useState(null);

  const totalPages = Math.ceil(rowCount / 10);

  const prepareData = useCallback(() => {
    return {
      page: pageIndex + 1,
      lang: "en",
    };
  }, [pageIndex]);

  const fetchData = useCallback(async () => {
    try {
      const data = await getAllServicesList(prepareData());
      if (data?.data?.services) {
        data.data.services.forEach(function (obj) {
          obj.typeName = obj.langData[0].typeName;
          obj.title = obj.langData[0].title;
          obj.shortText = obj.langData[0].shortText;
        });
        setServicesList(data.data.services);
        setrowCount(data.data.rowCount || 0);
      } else {
        
      }
    } catch (error) {
     
    }
  }, [prepareData]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
 

  useEffect(() => { }, [servicesList]);

 

  const handlePageChange = newPageIndex => {
    const clampedPageIndex = Math.max(0, Math.min(newPageIndex, totalPages - 1));
    setPageIndex(clampedPageIndex);
  };

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>{isAllRowsExpanded ? "🔽" : "▶️"}</span>
        ),
        Cell: ({ row }) => <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? "🔽" : "▶️"}</span>,
      },
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Image",
        accessor: "imgUrl",
        disableSort: true,
      },
      {
        Header: "Service Name",
        accessor: "title",
      },
      {
        Header: "Description",
        accessor: "shortText",
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableSort: true,
      },
    ],
    [],
  );
 

  const editChildRow = useCallback((row, rowUrl) => {
    setRowChildData(row);
    setRowUrl(rowUrl);
    setshowServiceEditChildModal(true);
  }, []);
  
  const addLand = useCallback((langType, rowUrl) => {
    setrowLangType(langType);
    setRowUrl(rowUrl);
    setshowServiceAddLangdModal(true);
  }, []); 

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <RowSubComponent
        row={row}
        addLand={addLand}
        editChildRow={editChildRow}
      />
    ),
    [addLand, editChildRow]
  );
  return (
    <>
      <div className='row col-lg-12'>
        <DataTable
          data={servicesList}
          columns={columns}
          sortable={true}
          setRowData={setRowData}
          setshowEditModal={setshowServiceEditModal}
          renderRowSubComponent={renderRowSubComponent}
          deleteButtonVisibilityClass='d-none'
          pageIndex={pageIndex}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      </div>
      <EditModal
        show={showServiceEditModal}
        modaldata={rowData}
        onHide={() => setshowServiceEditModal(false)}>
      </EditModal>
      <EditSubModal
        show={showServiceEditChildModal}
        modaldata={rowChildData}
        onHide={() => {
          setshowServiceEditChildModal(false);
          //if (rowData) {
           // const { url, lang, title } = rowData;
            // updateResourceList(url, lang, title);
          //}
          fetchData();
        }}
        rowurl={rowUrl}>
      </EditSubModal>
      <AddLangModal
        show={showServiceAddLangdModal}
        modaldata={rowChildData}
        onHide={() => {
          setshowServiceAddLangdModal(false);
          fetchData();
        }}
        rowurl={rowUrl}
        langtype={rowLangType}>
        </AddLangModal>
    </>
  );
}

export default ServicesTable;
