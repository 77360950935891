import { useState } from "react";

import { FiArrowRight } from "react-icons/fi";
import { submitEnquiry } from "../../../../service/publicServices";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import CkEditor from "../../components/Editor/CkEditor";

const MySwal = withReactContent(Swal);

function AddCarousels(formStyle) {
  const [IsSpinner, setIsSpinner] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };
  const [checkedBanner, setcheckedBanner] = useState(false);

  const handleBannerChange = () => {
    setcheckedBanner(!checkedBanner);
  };

  const prepareData = () => {
    return {};
  };
  const addCarousel = e => {
    e.preventDefault();
    setIsSpinner(true);

    submitEnquiry(prepareData()).then(
      result => {
        setIsSpinner(false);
        //setResult(true);
        if (result.data.code === "1") {
          MySwal.fire({
            confirmButtonColor: "#EC726B",
            title: <strong>Success !</strong>,
            html: <i>{result.data.description}</i>,
            icon: "success",
          });
        } else {
          MySwal.fire({
            confirmButtonColor: "#EC726B",
            title: <strong>Error !</strong>,
            html: <i>{result.data.description}</i>,
            icon: "error",
          });
        }
      },
      error => {
        setIsSpinner(false);
        MySwal.fire({
          confirmButtonColor: "#EC726B",
          title: <strong>Error !</strong>,
          html: <i>{error.data}</i>,
          icon: "error",
        });
      },
    );
    e.target.reset();
  };
  return (
    <form className={`${formStyle} pt-5`} action='' onSubmit={addCarousel}>
      <div className='form-group'>
        <label className='form-label'>Select Image</label>
        <input className='form-control' type='file' name='author' placeholder='Author' required />
      </div>
      <div className='form-group'>
        <label className='form-label'>Heading</label>
        <CkEditor name='about'></CkEditor>
      </div>
      <div className='form-group'>
        <label className='form-label'>Description</label>
        <CkEditor name='about'></CkEditor>
      </div>

      <div className='form-group'>
        <input type='checkbox' id='checkedBanner' checked={checkedBanner} onChange={handleBannerChange}></input>
        <label htmlFor='checkbox'>Banner Is Active</label>
      </div>
      <div className='row'>
        <div className='col-lg-3'>
          <div className='form-group'>
            <input type='text' name='ctaBtnName' placeholder='ctaBtnName' />
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='form-group'>
            <input type='text' name='ctaBtnUrl' placeholder='ctaBtnUrl' />
          </div>
        </div>
        <div className='col-lg-3 pt-3'>
          <div className='form-group'>
            <input type='checkbox' id='checkboxCtaBtn' checked={checked} onChange={handleChange}></input>
            <label htmlFor='checkbox'>Button Is Active</label>
          </div>
        </div>
      </div>

      <div className='row ps-5'>
        <div className='form-group  col-xl-4'>
          <button className='btn-default btn-small'>
            Save
            <i className='icon ms-3'>
              {IsSpinner ? (
                <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
              ) : (
                <FiArrowRight />
              )}
            </i>
          </button>
        </div>
        <div className='form-group col-xl-4'>
          <button className='btn-default btn-small'>
            Cancel
            <i className='icon ms-3'>
              {IsSpinner ? (
                <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
              ) : (
                <FiArrowRight />
              )}
            </i>
          </button>
        </div>
        <div className='form-group col-xl-4'>
          <button className='btn-default btn-small '>
            Preview
            <i className='icon ms-3'>
              {IsSpinner ? (
                <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
              ) : (
                <FiArrowRight />
              )}
            </i>
          </button>
        </div>
      </div>
    </form>
  );
}

export default AddCarousels;
