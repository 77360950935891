// TODO: REFACTOR

import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { updateVectraResourceDetail } from "../../../../service/publicServices";
import { FiArrowRight } from "react-icons/fi";
import CkEditor from "../../components/Editor/CkEditor";

function EditSubModal(props) {
  const optionsLang = [
    { value: "en", label: "English" },
    { value: "tr", label: "Turkish" },
    { value: "de", label: "German" },
    { value: "bd", label: "Bengladesh" },
  ];

  const language = {
    value: `${props?.modaldata?.lang}`,
    label: "English",
  };

  const [IsSpinner, setIsSpinner] = useState(false);

  const [twtitle, setTwTitle] = useState(props?.modaldata?.twTitle);
  const onChangeTwTitle = e => {
    setTwTitle(e.target.value);
  };

  const [twDescription, setTwDescription] = useState(props?.modaldata?.twDescription);
  const onChangeTwDescription = e => {
    setTwDescription(e.target.value);
  };

  const [twImageAlt, setTwImageAlt] = useState(props?.modaldata?.twImageAlt);
  const onChangeTwImageAlt = e => {
    setTwImageAlt(e.target.value);
  };
  const [content, setContent] = useState(props?.modaldata?.content);
  const onChangeContent = data => {
    setContent(data);
  };

  const [lang, setLang] = useState(null);
  const onChangeLang = e => {
    setLang(e.target.value);
  };

  const [title, setTitle] = useState(props?.modaldata?.title);
  const onChangeTitle = e => {
    setTitle(e.target.value);
  };

  const [metaTitle, setMetaTitle] = useState(props?.modaldata?.metaTitle);
  const onChangeMetaTitle = e => {
    setMetaTitle(e.target.value);
  };

  const [metaDescription, setMetaDescription] = useState(props?.modaldata?.metaDescription);
  const onChangeMetaDescription = e => {
    setMetaDescription(e.target.value);
  };
  const prepareData = () => {
    const formData = new FormData();

    let datatwtitle = twtitle ? twtitle : props?.modaldata?.twTitle;
    let datatwDescription = twDescription ? twDescription : props?.modaldata?.twDescription;
    let datatwImageAlt = twImageAlt ? twImageAlt : props?.modaldata?.twImageAlt;
    let datattitle = title ? title : props?.modaldata?.title;
    let datatmetaTitle = metaTitle ? metaTitle : props?.modaldata?.metaTitle;
    let datatmetaDescription = metaDescription ? metaDescription : props?.modaldata?.metaDescription;
    let datatcontent = content ? content : props?.modaldata?.content;

    const data = {
      url: props?.rowurl,
      resource: {
        langData: [
          {
            twTitle: datatwtitle,
            twDescription: datatwDescription,
            twImageAlt: datatwImageAlt,
            lang: props?.modaldata?.lang,
            title: datattitle,
            metaTitle: datatmetaTitle,
            metaDescription: datatmetaDescription,
            content: datatcontent,
          },
        ],
      },
    };
    formData.append("request", JSON.stringify(data));

    return formData;
  };

  const Result = myResultData => {
    var className1 = "";
    if (myResultData.myResultData.code === "1") {
      className1 = "success-message";
    } else {
      className1 = "error-message";
    }

    return <p className={`${className1}`}>{`${myResultData.myResultData.description}`}</p>;
  };

  const [result, setResult] = useState(false);
  setTimeout(() => {
    setResult(false);
  }, 10000);

  const updateResourceDetail = e => {
    e.preventDefault();
    setIsSpinner(true);

    const formData = prepareData();

    updateVectraResourceDetail(formData).then(
      result => {
        setIsSpinner(false);
        setResult(true);
      },
      error => {
        setIsSpinner(false);
      },
    );
    e.target.reset();
  };
  return (
    <Modal {...props} size='xl' aria-labelledby='contained-modal-title-vcenter' centered className='modal-custom-admin'>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <p className='modal-subtitle'>
            {" "}
            Edit : {props?.modaldata?.title} Lang : {props?.modaldata?.lang}{" "}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className='pt-3 ' action='' onSubmit={updateResourceDetail}>
          {/*  <div className='col-lg-12'>
                        <div className="form-group">
                            <label className="form-label">Language</label>
                            <Select pHolder="Language..."
                                options={optionsLang}
                                name="lang"
                                isMulti={false}
                                selectedvalue={language}
                            ></Select>
                        </div>
                    </div> */}
          <div className='col-lg-12'>
            <div className='form-group'>
              <label className='form-label'>twTitle</label>
              <input
                type='text'
                name='twTitle'
                placeholder='twTitle...'
                required
                onChange={onChangeTwTitle}
                defaultValue={props?.modaldata?.twTitle}
              />
            </div>
          </div>
          <div className='col-lg-12'>
            <div className='form-group'>
              <label className='form-label'>twDescription</label>
              <input
                type='text'
                name='twDescription'
                placeholder='twDescription...'
                required
                onChange={onChangeTwDescription}
                defaultValue={props?.modaldata?.twDescription}
              />
            </div>
          </div>
          <div className='col-lg-12'>
            <div className='form-group'>
              <label className='form-label'>twImageAlt</label>
              <input
                type='text'
                name='twImageAlt'
                placeholder='twImageAlt...'
                required
                onChange={onChangeTwImageAlt}
                defaultValue={props?.modaldata?.twImageAlt}
              />
            </div>
          </div>
          <div className='col-lg-12'>
            <div className='form-group'>
              <label className='form-label'>metaDescription</label>
              <input
                type='text'
                name='metaDescription'
                placeholder='metaDescription...'
                required
                onChange={onChangeMetaDescription}
                defaultValue={props?.modaldata?.metaDescription}
              />
            </div>
          </div>
          <div className='col-lg-12'>
            <div className='form-group'>
              <label className='form-label'>metaTitle</label>
              <input
                type='text'
                name='metaTitle'
                placeholder='tmetaTitle...'
                required
                onChange={onChangeMetaTitle}
                defaultValue={props?.modaldata?.metaTitle}
              />
            </div>
          </div>
          {/* <div className='row'>
                        <div className='col-lg-6'>
                            <div className="form-group">
                                <label className="form-label">Date</label>
                                <input
                                    className='form-control'
                                    type="date"
                                    name="date"
                                    placeholder="Date"
                                    required
                                    onChange={onChangeDate}
                                />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <label className="form-label">Time</label>
                            <div className="form-group">
                                <input
                                    className='form-control'
                                    type="time"
                                    name="time"
                                    placeholder="Time"
                                    required
                                    onChange={onChangeTime}
                                />
                            </div>
                        </div>
                    </div> */}
          {/*  <div className='row mb-5 ps-5'>
                        <div className='col-lg-2'>
                            <img src={props?.modaldata?.original.imgUrl} alt="expert-picture" width="80" height="80" />
                        </div>
                        <div className='col-lg-8'>
                            <label className="form-label">Select Image</label>
                            <input
                                className='form-control'
                                type='file'
                                name="author"
                                placeholder="Author"
                                required
                            >
                            </input>
                        </div>
                    </div> */}
          <div className='form-group'>
            <label className='form-label'>Heading</label>
            {/*<CkEditor*/}
            {/*    name="title"*/}
            {/*    editorData={props?.modaldata?.title}*/}
            {/*    onChange={onChangeTitle}*/}
            {/*></CkEditor>*/}
            <input
              type='text'
              name='title'
              placeholder='title..'
              required
              onChange={onChangeTitle}
              defaultValue={props?.modaldata?.title}
            />
          </div>
          <div className='form-group'>
            <label className='form-label'>Content</label>
            <CkEditor name='content' editorData={props?.modaldata?.content} onChange={onChangeContent}></CkEditor>
          </div>

          <div className='row ps-5'>
            <div className='form-group  col-xl-4'>
              <button className='btn-default btn-small'>
                Save
                <i className='icon ms-3'>
                  {IsSpinner ? (
                    <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
                  ) : (
                    <FiArrowRight />
                  )}
                </i>
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default EditSubModal;
