import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Error = ({ ERR_MSG /* STATIC PROP FROM SERVER SIDE */ }) => {
  const selectedCountry = useSelector(state => state.country);

  const { isCountrySelected, vectraSiteLocalizable, country } = selectedCountry;

  const ERROR_PAGE_TRANSLATION = {
    en: {
      head: "Looks like you are lost",
      para: "the page you are looking for is not available",
      linkLabel: "Go Back Home",
    },
    de: {
      head: "Es sieht so aus, als ob du dich verirrt hast",
      para: "Die Seite, die du suchst, ist nicht verfügbar",
      linkLabel: "Zurück zur Startseite",
    },
    tr: {
      head: "Kaybolduğun gibi görünüyor",
      para: "Aramakta olduğun sayfa bulunamadı",
      linkLabel: "Ana Sayfaya Dön",
    },
    bd: {
      head: "এটি একটি হারানো মনে হচ্ছে",
      para: "আপনি যে পৃষ্ঠাটি খুঁজছেন তা পাওয়া যায়নি",
      linkLabel: "বাড়িতে ফিরে যান",
    },
  };

  return (
    <div className='error-area ptb--200 ptb_sm--60 ptb_md--80'>
      <div className='container'>
        <div className='row align-item-center'>
          <div className='col-lg-12'>
            <div className='error-inner'>
              <h1>404</h1>
              <h2 className='title'> {ERROR_PAGE_TRANSLATION[country ? country.shortCode : "en"].head}</h2>
              <p className='description'> {ERROR_PAGE_TRANSLATION[country ? country.shortCode : "en"].para}</p>
              <div className='view-more-button'>
                <Link
                  className='btn-default'
                  to={"/" + isCountrySelected && vectraSiteLocalizable ? country.shortCode : ""}>
                  {ERROR_PAGE_TRANSLATION[country ? country.shortCode : "en"].linkLabel}
                </Link>
              </div>
              {ERR_MSG && (
                <div
                  style={{
                    backgroundColor: "rgba(255, 0, 0, 0.35)",
                    color: "rgba(255, 0, 0, 0.65)",
                    padding: "1rem",
                    border: "4rem",
                    width: "max-content",
                  }}>
                  <strong>
                    <em>Error: </em>
                  </strong>
                  {ERR_MSG}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
