import React from "react";
import PropTypes from "prop-types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import "./ckEditor.scss";
function CkEditor({ editorData, onChange }) {
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    onChange(data);
  };
  const editorConfig = {
    plugins: [],
    toolbar: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "blockQuote", "image"],
    heading: {
      options: [
        { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
        { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
        { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
      ],
    },
    image: {
      toolbar: ["imageTextAlternative", "|", "imageStyle:alignLeft", "imageStyle:full", "imageStyle:alignRight"],
      styles: ["full", "alignLeft", "alignRight"],
    },
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      data={editorData}
      onReady={editor => {}}
      onChange={handleEditorChange}
      onBlur={(event, editor) => {}}
      onFocus={(event, editor) => {}}
    />
  );
}
export default CkEditor;
