import { useEffect, useState, useMemo } from "react";
import EditModal from "./EditModal";
import DataTable from "../../components/DataTable/DataTable";
import { deleteOpenPosition, getOpenPositions } from "../../../../service/publicServices";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

function OpenPositionTable() {
    const [openPositions, setOpenPositions] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [showOpenPositionEditModal, setShowOpenPositionEditModal] = useState(false);
    const [rowData, setRowData] = useState(null);

    const fetchData = async () => {
        try {
            const data = await getOpenPositions();
            if (data?.data?.openPositions) {
                setOpenPositions(data.data?.openPositions);
                setRowCount(data.data?.rowCount);
            }
        } catch (e) {
            // TODO: HANDLE ERROR
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
            },
            {
                Header: "Title",
                accessor: "title",
            },
            {
                Header: "Job Country",
                accessor: "jobCountry",
                Cell: ({ value }) => (value ? value.label : ""),
            },
            {
                Header: "Work Type",
                accessor: "workLocation",
                Cell: ({ value }) => (value ? value.label : ""),
            },
            {
                Header: "Job Type",
                accessor: "jobType",
                Cell: ({ value }) => (value ? value.label : ""),
            },
            {
                Header: "Category",
                accessor: "category",
                Cell: ({ value }) => (value ? value.label : ""),
            },
            {
                Header: "Actions",
                accessor: "actions",
                Cell: ({ row }) => (
                    <button onClick={() => handleEdit(row.original)}>Edit</button>
                ),
            },
        ],
        []
    );

    const handleEdit = (data) => {
        setRowData(data);
        setShowOpenPositionEditModal(true);
    };

    const handleCloseModal = () => {
        setShowOpenPositionEditModal(false);
        fetchData(); // Refresh data after modal close
    };

    const deleteRow = (row) => {
        deleteOpenPosition(prepareDeleteData(row?.original?.id)).then(
            (result) => {
                if (result.data.code === "1") {
                    MySwal.fire({
                        confirmButtonColor: "#EC726B",
                        title: <strong>Success !</strong>,
                        html: <i>{result.data.description}</i>,
                        icon: "success",
                    });
                    fetchData(); // Refresh data after successful delete
                } else {
                    MySwal.fire({
                        confirmButtonColor: "#EC726B",
                        title: <strong>Error !</strong>,
                        html: <i>{result.data.description}</i>,
                        icon: "error",
                    });
                }
            },
            (error) => {
                MySwal.fire({
                    confirmButtonColor: "#EC726B",
                    title: <strong>Error !</strong>,
                    html: <i>{error.data}</i>,
                    icon: "error",
                });
            }
        );
    };

    const prepareDeleteData = (rowId) => {
        return {
            openPositionId: rowId,
        };
    };

    return (
        <>
            <div className="row col-lg-12">
                <DataTable
                    data={openPositions}
                    columns={columns}
                    sortable={true}
                    setRowData={setRowData}
                    setshowEditModal={setShowOpenPositionEditModal}
                    deleteRow={deleteRow}
                />
            </div>
            {showOpenPositionEditModal && (
                <EditModal
                    show={showOpenPositionEditModal}
                    modaldata={rowData}
                    onHide={handleCloseModal}
                    onSave={handleCloseModal} // Pass handleCloseModal as the onSave callback
                />
            )}
        </>
    );
}

export default OpenPositionTable;
