import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import Select from "../../components/select/Select";
import CkEditor from "../../components/Editor/CkEditor";
import IndustryList from "../../../../data/search/industries.json";
import CountryList from "../../../../data/search/country.json";
import ExpertiseList from "../../../../data/search/expertise.json";
import { updateExpert } from "../../../../service/publicServices";

function EditModal(props) {
  const ExpertiseListVL = ExpertiseList.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const selectedExpertList = props?.modaldata?.original.expertise?.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const selectedCountryList = props?.modaldata?.original.country?.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const selectedIndustryList = props?.modaldata?.original.sectors?.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const [IsSpinner, setIsSpinner] = useState(false);
  const [resultData, setResultData] = useState(null);

  const [image, setImage] = useState(null);
  const [selectedImage, setselectedImage] = useState(null);

  const handleUpload = e => {
    var file = e.target.files;
    const imageUrl = URL.createObjectURL(file[0]);
    setImage(file[0]);
    setselectedImage(imageUrl);
  };
  const [expertName, setExpertName] = useState(props?.modaldata?.original.name);
  const onChangeExpertName = e => {
    setExpertName(e.target.value);
  };

  const [order, setOrder] = useState(props?.modaldata?.original.order);
  const onChangeOrder = e => {
    setOrder(e.target.value);
  };
  const [title, setTitle] = useState(props?.modaldata?.original.title);
  const onChangeTitle = e => {
    setTitle(e.target.value);
  };
  const [description, setDescription] = useState(props?.modaldata?.original.description);
  const onChangeDescription = e => {
    setDescription(e);
  };

  const [quote, setQuote] = useState(props?.modaldata?.original.quote);
  const onChangeQuote = e => {
    setQuote(e);
  };

  const [expertise, setExpertise] = useState(null);
  const onChangeExpertise = selectedOption => {
    setExpertise(selectedOption);
  };

  const [sectors, setSectors] = useState(null);
  const onChangeSectors = selectedOption => {
    setSectors(selectedOption);
  };

  const [country, setCountry] = useState(null);
  const onChangeCountry = selectedOption => {
    setCountry(selectedOption);
  };


  const prepareData = () => {
    const formData = new FormData();
    let dataexpertName = expertName ? expertName : props?.modaldata?.original.name;
    let datagender = "";
    let dataorder = order ? order : props?.modaldata?.original.order;
    let datatitle = title ? title : props?.modaldata?.original.title;
    let datadescription = description ? description : props?.modaldata?.original.description;
    let dataquote = quote ? quote : props?.modaldata?.original.quote
    let datacountry = country ? country : props?.modaldata?.original.country;
    let datasectors = sectors ? sectors : props?.modaldata?.original.sectors;
    let dataexpertise = expertise ? expertise : props?.modaldata?.original.expertise;
    const data = {
      url: props?.modaldata?.original.url,
      expert: {
        name: dataexpertName,
        gender: datagender,
        order: parseInt(dataorder),
        title: datatitle,
        description: datadescription,
        imageUrl: props?.modaldata?.original.imageUrl,
        quote: dataquote,
        url: props?.modaldata?.original.url,
        status: props?.modaldata?.original.status,
        country: datacountry,
        sectors: datasectors,
        expertise: dataexpertise
      }
    }
    data.expert.country = data.expert.country.map(country => ({
      id: country.id,
      name: country.name,
      shortCode: country.shortCode,
    }));
    data.expert.expertise = data.expert.expertise.map(expertise => ({
      id: expertise.id,
      name: expertise.name,
    }));
    data.expert.sectors = data.expert.sectors.map(sectors => ({
      id: sectors.id,
      name: sectors.name,
    }));
    formData.append("request", JSON.stringify(data));
    formData.append("file", image);
    return formData;
  };


  const Result = myResultData => {
    var className1 = "";
    if (myResultData.myResultData.code === "1") {
      className1 = "success-message";
    } else {
      className1 = "error-message";
    }

    return <p className={`${className1}`}>{`${myResultData.myResultData.description}`}</p>;
  };

  const [result, setResult] = useState(false);
  setTimeout(() => {
    setResult(false);
  }, 10000);

  const saveChanges = e => {
    e.preventDefault();
    setIsSpinner(true);
    updateExpert(prepareData()).then(
      result => {
        setIsSpinner(false);
        setResult(true);
        setResultData(result.data);
      },
      error => {
        setIsSpinner(false);
        setResultData(result.data);
      },
    );
    e.target.reset();
    setselectedImage(null);


    e.target.reset();
  };
  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered className='modal-custom-admin'>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <p className='modal-subtitle'> Edit : {props?.modaldata?.original.name} </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className={`pt-3`} action='' onSubmit={saveChanges}>
          <div className='row mb-5 ps-5 '>
            <div className='col-lg-2 pt-3'>
              <img src={props?.modaldata?.original.imageUrl} alt='service-picture' width='90' height='90' />
            </div>
            <div className='col-lg-8 pt-4'>
              <input className='form-control'
                id='formFileSm'
                type='file'
                name='file'
                placeholder='Upload Image'
                onChange={handleUpload}
              >
              </input>
              {selectedImage &&
                <div className='p-5'>
                  <label className='form-label'>Selected Image</label>
                  <img src={selectedImage} alt="Selected Image" width="200" />
                </div>
              }
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='form-label'>Name</label>
                <input
                  type='text'
                  name='expertName'
                  placeholder='Expert Name'
                  required
                  onChange={onChangeExpertName}
                  defaultValue={props?.modaldata?.original.name}
                />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='form-label'>Title</label>
                <input
                  type='text'
                  name='title'
                  placeholder='Title'
                  required
                  onChange={onChangeTitle}
                  defaultValue={props?.modaldata?.original.title}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-2'>
              <div className='form-group'>
                <label className='form-label '>Order</label>
                <input type='number' name='order' placeholder='order' required onChange={onChangeOrder} defaultValue={props?.modaldata?.original.order} />
              </div>
            </div>
          </div>
          <div className='form-group'>
            <label className='form-label'>Description</label>
            <CkEditor name='description' editorData={props?.modaldata?.original.description} onChange={onChangeDescription}></CkEditor>
          </div>
          <div className='form-group'>
            <label className='form-label'>Quote</label>
            <CkEditor name='description' editorData={props?.modaldata?.original.quote} onChange={onChangeQuote}></CkEditor>
          </div>
          <div className='form-group'>
            <label className='form-label'>Expertise</label>
            <Select
              pHolder='Expertise'
              options={ExpertiseListVL}
              name='expertise'
              selectedvalue={selectedExpertList}
              isMulti={true}
              onValueChange={onChangeExpertise}
            ></Select>

          </div>
          <div className='form-group'>
            <label className='form-label'>Industry / Sector Serve</label>
            <Select
              pHolder='Industry / Sector Serve'
              options={IndustryList}
              name='industry'
              selectedvalue={selectedIndustryList}
              isMulti={true}
              onValueChange={onChangeSectors}
            ></Select>

          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='form-group'>
                <label className='form-label'>Country Serve</label>
                <Select
                  pHolder='Country Serve'
                  options={CountryList}
                  name='country'
                  selectedvalue={selectedCountryList}
                  isMulti={true}
                  onValueChange={onChangeCountry}>
                </Select>
              </div>
            </div>
          </div>

          <div className='row ps-5'>
            <div className='form-group  col-xl-6'>
              <button className='btn-default btn-small'>
                Save
                <i className='icon ms-3'>
                  {IsSpinner ? (
                    <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
                  ) : (
                    <FiArrowRight />
                  )}
                </i>
              </button>
            </div>
            <div className='form-group'>{result ? <Result myResultData={resultData} /> : null}</div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default EditModal;
