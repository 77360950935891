import React, { useState } from 'react';
import TestimonialLangDetailList from "./TestimonialLangDetailList";
import langNames from "../../../../configs/LangNames";

const TestimonialList = ({ testimonials, onEdit, onDelete, startAddingLanguage }) => {
    const [expandedId, setExpandedId] = useState(null);

    const toggleExpand = (id) => {
        // If the current expanded ID is clicked again, collapse it, otherwise expand the new one
        setExpandedId(expandedId === id ? null : id);
    };

    return (
        <div>
            {testimonials.map(testimonial => (
                <div className='row border-bottom' key={testimonial.id}>
                    <div className='col-9' onClick={() => toggleExpand(testimonial.id)}>
                        <div><label className='fw-bold text-black'>ID:</label> {testimonial.id}</div>
                        <div><label className='fw-bold text-black'>Order:</label> {testimonial.order}</div>
                    </div>
                    <div className='col-3'>
                        <button className='btn btn-danger' onClick={(e) => { e.stopPropagation(); onDelete(testimonial.id); }}>Delete</button>
                    </div>

                    {/* Conditionally render this component based on whether the current testimonial is expanded */}
                    {expandedId === testimonial.id && (
                        <TestimonialLangDetailList
                            testimonial={testimonial}
                            onEdit={onEdit}
                            startAddingLanguage={startAddingLanguage}
                            availableLanguages={langNames}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

export default TestimonialList;
