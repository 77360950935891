import { useState } from "react";
import { FiArrowRight } from "react-icons/fi";

import Select from "../../../../components/dropDown/DropDown";
import CkEditor from "../../components/Editor/CkEditor";
import JobLocationList from "../../../../data/search/jobLocation.json";
import JobTypeList from "../../../../data/search/jobType.json";
import JobCategoryList from "../../../../data/search/jobCategory.json";
import JobCountryList from "../../../../data/search/country.json";
import { createOpenPosition } from "../../../../service/publicServices";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);
function AddOpenPosition() {
  const [IsSpinner, setIsSpinner] = useState(false);

  const [title, setTitle] = useState(null);
  const onChangeTitle = e => {
    setTitle(e.target.value);
  };

  const [jobLocation, setJobLocation] = useState(null);
  const onChangeJobLocation = e => {
    setJobLocation({ value: e.value, label: e.label });
  };

  const [jobType, setJobType] = useState(null);
  const onChangeJobType = e => {
    setJobType({ value: e.value, label: e.label });
  };

  const [jobCountry, setJobCountry] = useState(null);
  const onChangeJobCountry = e => {
    setJobCountry({ value: e.value, label: e.label });
  };

  const [jobCategory, setJobCategory] = useState(null);
  const onChangeJobCategory = e => {
    setJobCategory({ value: e.value, label: e.label });
  };

  const [jobBody, setJobBody] = useState(null);
  const onChangeJobBody = e => {
    setJobBody(e);
  };
  const [jobDate, setJobDate] = useState(new Date());

  const prepareData = () => {
    return {
      category: jobCategory,
      date: jobDate,
      jobCountry: jobCountry,
      jobType: jobType,
      workLocation: jobLocation,
      title: title,
      body: jobBody,
    };
  };

  const savePosition = e => {

    e.preventDefault();

    createOpenPosition(prepareData()).then(
      result => {
        setIsSpinner(false);

        if (result.data.code === "1") {
          MySwal.fire({
            confirmButtonColor: "#EC726B",
            title: <strong>Success !</strong>,
            html: <i>{result.data.description}</i>,
            icon: "success",
          });
        } else {
          MySwal.fire({
            confirmButtonColor: "#EC726B",
            title: <strong>Error !</strong>,
            html: <i>{result.data.description}</i>,
            icon: "error",
          });
        }
      },
      error => {
        setIsSpinner(false);
        MySwal.fire({
          confirmButtonColor: "#EC726B",
          title: <strong>Error !</strong>,
          html: <i>{error.data}</i>,
          icon: "error",
        });
      },
    );
  };
  return (
    <>
      <form className={`pt-3`} action='' onSubmit={savePosition}>
        <div className='form-group'>
          <label className='form-label'>Title</label>
          <input type='text' name='title' placeholder='Title' required onChange={onChangeTitle} />
        </div>

        <div className='form-group'>
          <label className='form-label'>Body</label>
          <CkEditor name='body' onChange={onChangeJobBody}></CkEditor>
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='form-group'>
              <label className='form-label'>Job Location</label>
              <Select
                pHolder='Job Location'
                items={JobLocationList}
                name='location'
                isMulti={false}
                handleSelectionChange={onChangeJobLocation}></Select>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='form-group'>
              <label className='form-label'>Job Type</label>
              <Select
                pHolder='Job Type'
                items={JobTypeList}
                name='jobType'
                isMulti={false}
                handleSelectionChange={onChangeJobType}></Select>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='form-group'>
              <label className='form-label'>Job Country</label>
              <Select
                pHolder='Job Country'
                items={JobCountryList}
                name='jobCountry'
                isMulti={false}
                handleSelectionChange={onChangeJobCountry}></Select>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='form-group'>
              <label className='form-label'>Job Category</label>
              <Select
                pHolder='Job Category'
                items={JobCategoryList}
                name='jobCategory'
                isMulti={false}
                handleSelectionChange={onChangeJobCategory}></Select>
            </div>
          </div>
        </div>

        <div className='row ps-5'>
          <div className='form-group  col-xl-6'>
            <button className='btn-default btn-small'>
              Save
              <i className='icon ms-3'>
                {IsSpinner ? (
                  <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
                ) : (
                  <FiArrowRight />
                )}
              </i>
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddOpenPosition;
