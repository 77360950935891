// TestimonialForm.jsx
import React, { useState, useEffect } from 'react';
//THIS FORM SHOULD HANDLE DİFFERENT LANGS as well
const TestimonialForm = ({ testimonial, editingLang, onSave, onCancel , actionAdd, actionAddLang, actionEdit }) => {
    const [formValues, setFormValues] = useState({
        id: '',
        lang: 'en',
        text: '',
        title: '',
        unit: '',
        order:0
    });

    useEffect(() => {
        // Check if editing an existing testimonial
        if(actionAddLang && testimonial&& editingLang) {

            setFormValues({
                id: testimonial.id,
                lang: editingLang,
                text: '',
                title: '',
                unit: '',
                order:testimonial.order
            });

        }

        else if(actionEdit && editingLang && testimonial ){

            const enVersion = testimonial.langData.find(lang => lang.lang === editingLang) || testimonial.langData[0];
            setFormValues({
                id: testimonial.id,
                lang: enVersion.lang,
                text: enVersion.text,
                title: enVersion.title,
                unit: enVersion.unit,
                order:enVersion.order
            });
        } else if(actionAdd){
            // Reset form for a new testimonial
            setFormValues({
                id: '',
                lang: 'en',
                text: '',
                title: '',
                unit: '',
                order:0
            });
        }
    }, [testimonial]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {

        e.preventDefault();
        onSave(formValues);
    };

    return (
        <form onSubmit={handleSubmit}>
            <label htmlFor='text'>Text</label>
            <input name="text"  type='text' value={formValues.text} onChange={handleChange} />
            <label htmlFor='title'>Title</label>
            <input name="title"  type='text' value={formValues.title} onChange={handleChange}/>
            <label htmlFor='unit'>Unit</label>
            <input name="unit" type='text' value={formValues.unit} onChange={handleChange} />
            <label htmlFor='order'>Order</label>
            <input name="order" type='number' value={formValues.order} onChange={handleChange} />
            {/* Add inputs for other fields as needed */}
            <button type="submit">Save</button>
            <button type="button" onClick={onCancel}>Cancel</button>
        </form>
    );
};

export default TestimonialForm;