import { combineReducers } from "redux";
import sessionReducer from "./sessionReducer";
import countryReducer from "./country";
import langReducer from "./language";
// import other reducers

const rootReducer = combineReducers({
  session: sessionReducer,
  country: countryReducer,
  lang: langReducer,
  // other reducers...
});

export default rootReducer;
