import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
// FOR BRANDS ADD COUNTRY PAGE IN THE FUTURE [ VI-TECH, FUTUREFITSME, TEDD]
// VECTRA HAS ALREADY COUNTRY PAGES.
const Logo = ({ image, image2, url }) => {
  const selectedCountry = useSelector(state => state.country);
  // const location = useLocation();

  // const brands = ["vi-tech", "futurefitsme", "tedd"]; // "about-us" url part is for "vectra" brand //todo: redirect the vectra brand pages to their urls

  const regionalRoute =
    selectedCountry.vectraSiteLocalizable && selectedCountry.isCountrySelected
      ? (selectedCountry.country.fullName === "भारत" ? "in" : selectedCountry.country.shortCode) + "/"
      : ""; // भारत , India
  return (
    <div className='logo'>
      <Link
        // THIS LOGIC WILL BE CHANGED IN THE FUTURE WHEN COUNTRY PAGES ARE ADDED TO VECTRA BRANDS
        to={"/" + regionalRoute + url}>
        <img className='logo-light' src={image} alt='Corporate Logo' />
        <img className='logo-dark' src={image2} alt='Corporate Logo' />
      </Link>
    </div>
  );
};
Logo.propTypes = {
  image: PropTypes.string,
};

export default Logo;
