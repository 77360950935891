import TestimonialsList from "./TestimonialsList";
import ErrorBoundary from "./ErrorBoundry";
import TestimonialsManager from "./TestimonialManager";

function AdminTestimonials() {
  return (
    <div className='row'>
      <div className='col-lg-12'>
        <div className=''>
            <ErrorBoundary>
              <TestimonialsManager/>
            </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}

export default AdminTestimonials;
