import React, { useState, useEffect } from "react";
import CustomSelect from "react-select";

function Select({ pHolder, options, selectedvalue, isMulti, onValueChange }) {
    const [selectedValues, setSelectedValues] = useState(selectedvalue);

    // Update state when selectedvalue prop changes
    useEffect(() => {
        setSelectedValues(selectedvalue);
    }, [selectedvalue]);

    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: "white" }),
        option: (styles, { data }) => {
            return { ...styles, color: data.color };
        },
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: "#ec726b",
                color: "#fff",
            };
        },
        multiValueLabel: (styles, { data }) => {
            return {
                ...styles,
                color: "#fff",
            };
        },
        multiValueRemove: (styles, { data }) => {
            return {
                ...styles,
                color: "#fff",
                cursor: "pointer",
                ":hover": {
                    color: "#fff",
                },
            };
        },
    };

    const handleChange = (selectedOption) => {
        setSelectedValues(selectedOption);
        onValueChange(selectedOption);
    };

    return (
        <CustomSelect
            options={options}
            onChange={handleChange}
            isMulti={isMulti}
            styles={colorStyles}
            placeholder={pHolder}
            value={selectedValues}
        />
    );
}

export default Select;
