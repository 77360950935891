import ExpertTable from "./ExpertTable";

function ManageExpert() {
  return (
    <>
      <div className='row col-lg-10'>
        <ExpertTable></ExpertTable>
      </div>
    </>
  );
}

export default ManageExpert;
