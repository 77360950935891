import React from 'react';


const TestimonialLangDetailList = ({ testimonial, onEdit, onAdd, availableLanguages ,startAddingLanguage}) => {
    return (
        <div>
            {Object.entries(availableLanguages).map(([langCode, langName]) => {
                const langDetail = testimonial.langData.find(lang => lang.lang === langCode);

                if (langDetail) {
                    return (
                        <div className='row ' key={langCode}>
                            <div className='col-9'>
                                <div><label className='fw-bold text-black'>{langCode}</label></div>
                                <div><label className='fw-bold text-black-50'>Text:</label> {langDetail.text}</div>
                                <div><label className='fw-bold text-black-50'>Title:</label> {langDetail.title}</div>
                                <div><label className='fw-bold text-black-50'>Unit:</label> {langDetail.unit}</div>
                                <div><label className='fw-bold text-black-50'>Order:</label> {testimonial.order}</div>
                            </div>
                            <div className='col-3'>
                                <button className='btn btn-warning' onClick={() => onEdit(testimonial, langCode)}>Edit {langName}</button>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div key={langCode} className='row '>
                            <div className='col-9'>
                                <div><label className='fw-bold text-black'>{langCode}</label></div>
                            </div>
                            <div className='col-3'>
                                <button className='btn btn-primary' onClick={() => startAddingLanguage(testimonial, langCode)}>Add {langName}</button>
                            </div>
                        </div>
                    );
                }
            })}
        </div>
    );
};
export default TestimonialLangDetailList;