export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_2 = "REGISTER_2";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const FORGOT_FAIL = "FORGOT_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const SET_LANG = "SET_LANG";
export const SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

/*export const chatTypes = {
    JOIN: 'JOIN',
    LEAVE: 'LEAVE',
    RESET_CHAT: 'RESET_CHAT',
    SET_ERRORS: 'SET_ERRORS',
    GROUP_CHAT: 'GROUP_CHAT',
    PRIVATE_CHAT: 'PRIVATE_CHAT',
    SET_STOMPCLIENT: 'SET_STOMPCLIENT',
    LOAD_GROUP_CHAT: 'LOAD_GROUP_CHAT',
    LOAD_USER_GROUP: 'LOAD_USER_GROUP',
    CLEAR_CHAT_ALERT: 'CLEAR_CHAT_ALERT',
    CLEAN_CHATTHREAD: 'CLEAN_CHATTHREAD',
    SET_PARTNERSINFO: 'SET_PARTNERSINFO',
    CLEAR_GROUP_CHAT: 'CLEAR_GROUP_CHAT',
    DELETE_GROUP_CHAT: 'DELETE_GROUP_CHAT',
    LOAD_PRIVATE_CHAT: 'LOAD_PRIVATE_CHAT',
    LOAD_CONVERSATION: 'LOAD_CONVERSATION',
    READ_PRIVATE_CHAT: 'READ_PRIVATE_CHAT',
    UPDATE_READ_STATUS: 'UPDATE_READ_STATUS',
    DELETE_PRIVATE_CHAT: 'DELETE_PRIVATE_CHAT',
    CLEAR_PRIVATE_CHATS: 'CLEAR_PRIVATE_CHATS',
    RESET_CHAT_CONNECTION: 'RESET_CHAT_CONNECTION',
  };*/
