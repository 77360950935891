import api from "./api";
import TokenService from "./token.service";
// import axios from "axios";
import { setSessionData } from "./sessionStorage"; // Import the setSessionData function from your sessionStorage file

const headers = {
  headers: {
    "Access-Control-Allow-Headers": "Content-Type, Authorization",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

class AuthService {
  login(email, password) {
    const body = { userName: email, password: password };
    return api.post("vectra/auth/signin", body, headers).then(response => {
      if (response.data.accessToken) {
        TokenService.setUser(response.data);
      }

      // Save session data in session storage
      setSessionData(response.data);

      return response.data;
    });
  }

  forgotPasword(email) {
    const body = { email: email };
    return api.post("vectra/auth/forgotPassword", body, headers).then(response => {
      return response.data;
    });
  }

  logout() {
    TokenService.removeUser();
  }

  register(firstName, lastName, countryId, email, password, repassword) {
    return api
      .post("vectra/auth/signUp/v2", {
        firstName,
        lastName,
        countryId,
        email,
        password,
        repassword,
      })
      .then(response => {
        return response.data;
      });
  }
}

export default new AuthService();
