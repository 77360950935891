import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";

function DropDown({ items, selected, handleSelectionChange, label }) {
  const { t } = useTranslation();

  const [defaultLabel, setDefaultLabel] = useState({
    label: t("vb.ddlabel"),
    value: 0,
  });

  useEffect(() => {
    if (selected && selected > 0) {
      setDefaultLabel({
        label: label,
        value: selected,
      });
    }
  }, []);

  const handleChange = selectedOption => {
    handleSelectionChange(selectedOption);
    setDefaultLabel({
      label: selectedOption.label,
      value: selectedOption.value,
    });
  };

  const loadOptions = (serchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = items.filter(item => item.label.toLowerCase().includes(serchValue.toLowerCase()));
      callback(filteredOptions);
    }, 10);
  };
  return <AsyncSelect loadOptions={loadOptions} defaultOptions value={defaultLabel} onChange={handleChange} />;
}

export default DropDown;
