import Logo from "../../../../components/logo/Logo";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./login.scss";
import LoginForm from "./LoginForm";
import ForgotpassForm from "./ForgotpassForm";

function Login() {
  return (
    <main className='page-wrapper'>
      <div className='login-page'>
        <div className='login-modal'>
          <div className='col-lg-12'>
            <Logo image={"/images/logo/vectra-logo.svg"} image2={"/images/logo/vectra-logo.svg"} url='' />
            <h6>VECTRA International Member</h6>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <Tabs>
                <div className='row row--10 align-items-center'>
                  <div className='col-lg-12'>
                    <div className='rn-default-tab style-three'>
                      <div className='tab-button-panel'>
                        <TabList className='tab-button'>
                          <Tab>
                            <div className='rn-tab-button'>
                              <button>Login</button>
                            </div>
                          </Tab>
                          <Tab>
                            <div className='rn-tab-button'>
                              <button>Forgot Password</button>
                            </div>
                          </Tab>
                        </TabList>
                      </div>
                      <div className='tab-content-panel'>
                        <TabPanel>
                          <LoginForm />
                        </TabPanel>
                        <TabPanel>
                          <ForgotpassForm />
                        </TabPanel>
                      </div>
                    </div>
                  </div>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;
