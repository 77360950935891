import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { updateVectraService } from "../../../../service/publicServices";

// TODO:  REFACTOR ( USE INLINE FNS FOR EVENT HANDLERS IF CODES CAN BE WRITTEN WITH ONE OR TWO LINES)
function EditModal(props) {



  const [IsSpinner, setIsSpinner] = useState(false);
  const [resultData, setResultData] = useState(null);

  const [image, setImage] = useState(null);
  const [selectedImage, setselectedImage] = useState(null);
  
  const handleUpload = e => {
    var file = e.target.files;
    const imageUrl = URL.createObjectURL(file[0]);
    setImage(file[0]);
    setselectedImage(imageUrl);
  };

 /* 
  const prepareForm = () => {
    let bodyFormData = new FormData();

    bodyFormData.append("file", image);
    bodyFormData.append("request", JSON.stringify(prepareData()));
    return bodyFormData;
  };
  const prepareData = () => {
     return {
       iconUrl: props?.modaldata?.original.iconUrl,
       imgUrl:props?.modaldata?.original.imgUrl,
       status:props?.modaldata?.original.status,
       url:props?.modaldata?.original.url
     };
   }; */

  const prepareData = () => {
    const formData = new FormData();
    const data = {
      url: props?.modaldata?.original.url,
      service: {
        iconURL: props?.modaldata?.original.iconUrl,
        imgUrl: props?.modaldata?.original.imgUrl,
        status: props?.modaldata?.original.status,
        url: props?.modaldata?.original.url,
        langData: props?.modaldata?.original.langData
      }
    }
    formData.append("request", JSON.stringify(data));
    formData.append("file", image);
    return formData;
  };
  const Result = myResultData => {
    var className1 = "";
    if (myResultData.myResultData.code === "1") {
      className1 = "success-message";
    } else {
      className1 = "error-message";
    }
    return <p className={`${className1}`}>{`${myResultData.myResultData.description}`}</p>;
  };

  const [result, setResult] = useState(false);
  setTimeout(() => {
    setResult(false);
  }, 10000);

  const updateService = e => {
    e.preventDefault();
    setIsSpinner(true);
    updateVectraService(prepareData()).then(
      result => {
        setIsSpinner(false);
        setResult(true);
        setResultData(result.data);
      },
      error => {
        setIsSpinner(false);
        setResultData(result.data);
      },
    );
    e.target.reset();
    setselectedImage(null);

  };

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered className='modal-custom-admin'>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <p className='modal-subtitle'> Edit : {props?.modaldata?.original.text} </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className={`pt-3`} action='' onSubmit={updateService}>
          <div className='row mb-5 ps-5 '>
            <div className='col-lg-2 pt-3'>
              <img src={props?.modaldata?.original.imgUrl} alt='service-picture' width='90' height='90' />
            </div>
            <div className='col-lg-8 pt-4'>
              <input className='form-control'
                id='formFileSm'
                type='file'
                name='file'
                placeholder='Upload Image'
                onChange={handleUpload}
                required
              >
              </input>
              {selectedImage &&
                <div className='p-5'>
                  <label className='form-label'>Selected Image</label>
                  <img src={selectedImage} alt="Selected Image" width="200" />
                </div>
              }
            </div>
          </div>
          <div className='row ps-5'>
            <div className='form-group  col-xl-6'>
              <button className='btn-default btn-small'>
                Save
                <i className='icon ms-3'>
                  {IsSpinner ? (
                    <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
                  ) : (
                    <FiArrowRight />
                  )}
                </i>
              </button>
            </div>
            <div className='form-group'>{result ? <Result myResultData={resultData} /> : null}</div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default EditModal;
