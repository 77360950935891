import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { createMainPageCarousel, getMainPageCarousels } from "../../../../service/publicServices";

const MainPageCarouselForm = () => {
    const [file, setFile] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [formData, setFormData] = useState({
        en: { heading: '', subHeading: '' },
        tr: { heading: '', subHeading: '' },
        de: { heading: '', subHeading: '' },
        bd: { heading: '', subHeading: '' },
        hi: { heading: '', subHeading: '' },
        cm: { heading: '', subHeading: '' },
        pt: { heading: '', subHeading: '' },
    });

    const langNames = {
        en: "English",
        tr: "Türkçe",
        de: "Deutsch",
        bd: "Bangla",
        hi: "Hindi",
        cm: "Mandarin",
        pt: "Portuguese"
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getMainPageCarousels();
                if (response.data.carousels && response.data.carousels.length > 0) {
                    const carousels = response.data.carousels[0];  // Assuming the server sends an array of carousels
                    const newFormData = { ...formData };
                    carousels.langData.forEach(langData => {
                        if (newFormData[langData.lang]) {
                            newFormData[langData.lang] = {
                                heading: langData.heading,
                                subHeading: langData.subHeading
                            };
                        }
                    });
                    setFormData(newFormData);
                    if (carousels.img) {
                        setImagePreviewUrl(carousels.img);
                    }
                }
            } catch (error) {
                console.error('Failed to fetch carousels', error);
            }
        };
        fetchData();
    }, []);  // Dependency array is empty, so this runs only once after the initial render

    const handleInputChange = (lang, field, value) => {
        setFormData(prev => ({
            ...prev,
            [lang]: {
                ...prev[lang],
                [field]: value
            }
        }));
    };

    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            setFile(file);
            setImagePreviewUrl(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handleSubmit = async (lang) => {
        const data = new FormData();
        if (file) {
            data.append('file', file);
        }
        data.append('request', JSON.stringify({
            carousel: {
                langData: [{
                    lang,
                    heading: formData[lang].heading,
                    subHeading: formData[lang].subHeading
                }]
            }
        }));

        try {
            const response = await createMainPageCarousel(data);
            alert(`Response: ${response.data.description}`);
        } catch (error) {
            alert(`Error: ${error.response.data.description || 'Something went wrong'}`);
        }
    };

    return (
        <div>
            <h1>Update Carousel</h1>
            <div className="row">
                <div className="col-6">
                    {Object.keys(langNames).map((lang) => (
                        <div key={lang}>
                            <h5 style={{ color: "#571c1c" }}>{langNames[lang]}</h5>
                            <label>Heading:</label>
                            <input
                                type="text"
                                value={formData[lang].heading}
                                onChange={(e) => handleInputChange(lang, 'heading', e.target.value)}
                            />
                            <label>Subheading:</label>
                            <input
                                type="text"
                                value={formData[lang].subHeading}
                                onChange={(e) => handleInputChange(lang, 'subHeading', e.target.value)}
                            />
                            <button onClick={() => handleSubmit(lang)}>Submit for {langNames[lang]}</button>
                        </div>
                    ))}
                </div>
                <div className="col-6">
                    <input type="file" onChange={handleImageChange} />
                    {imagePreviewUrl && (
                        <div>
                            <h2>Preview:</h2>
                            <img src={imagePreviewUrl} alt="Carousel Preview" style={{ width: '500px', height: 'auto' }} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MainPageCarouselForm;
