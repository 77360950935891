import React from "react";
import { useTable, useSortBy, usePagination, useExpanded } from "react-table";

import "./Table.scss";
/* import { deleteVectraResource } from "../../../../service/publicServices"; */

function DataTable({
  data,
  columns,
  renderRowSubComponent,
  setRowData,
  setshowEditModal,
  deleteButtonVisibilityClass,
  pageIndex,
  onPageChange,
  totalPages,
  deleteRow,
}) {
  //console.log(data);
  const handlePageChange = pageIndex => {
    onPageChange(pageIndex);
  };
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, visibleColumns, nextPage, previousPage } =
    useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 10 },
      },
      useSortBy,
      useExpanded,
      usePagination,
    );

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  /* const deleteRow = row => {
    const delJson = { url: row?.original?.url };
    const formData = new FormData();
    formData.append("request", JSON.stringify(delJson));

    deleteVectraResource(formData)
      .then(response => {})
      .catch(error => {});
  }; */

  const editRow = row => {
    setRowData(row);
    setshowEditModal(true);
  };

  const ActionCell = ({ row }) => {
    return (
      <div className='dataTableactionBtnDiv'>
        <button className='btn btn-primary btn-lg' onClick={() => editRow(row)}>
          Edit
        </button>
        <button className={`btn btn-danger btn-lg ms-2 ${deleteButtonVisibilityClass}`} onClick={() => deleteRow(row)}>
          Delete
        </button>
      </div>
    );
  };

  return (
    <>
      <table {...getTableProps()} className='table table-hover'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div>
                    {column.render("Header")}
                    <span>{generateSortingIndicator(column)}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);

            return (
              <React.Fragment key={row.getRowProps().key}>
                <tr {...row.getRowProps()} rowurl={row.original.rowUrl}>
                  {row.cells.map((cell, idx) => {
                    if (cell.column.id === "imageUrl" || cell.column.id === "imgUrl" || cell.column.id === "url") {
                      return (
                        <td key={idx} {...cell.getCellProps()}>
                          <img src={cell.value} alt='expert' width='60' height='60' />
                        </td>
                      );
                    } else if (cell.column.id === "actions") {
                      return (
                        <td key={idx}>
                          <ActionCell row={row} />
                        </td>
                      );
                    } else {
                      return (
                        <td {...cell.getCellProps()} key={idx}>
                          {Array.isArray(cell.value)
                            ? cell.value.map(location => location.label).join(", ")
                            : typeof cell.value === "object" && cell.value !== null
                            ? cell.value.label
                            : cell.render("Cell")}
                        </td>
                      );
                    }
                  })}
                </tr>

                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div>
        <button
          className='btn btn-warning btn-lg'
          onClick={() => {
            previousPage();
            handlePageChange(pageIndex - 1);
          }}
          disabled={pageIndex === 0}>
          Previous Page
        </button>{" "}
        <button
          className='btn btn-warning btn-lg'
          onClick={() => {
            nextPage();
            handlePageChange(pageIndex + 1);
          }}
          disabled={pageIndex === totalPages - 1}>
          Next Page
        </button>{" "}
        <span>
          Page {pageIndex + 1} of {totalPages}
        </span>{" "}
      </div>
    </>
  );
}

export default DataTable;