import CarouselTable from "./CarouselsTable";

function ManageCarousels() {
  return (
    <>
      <div className='row col-lg-10'>
        {/*<CarouselTable></CarouselTable>*/}


      </div>
    </>
  );
}

export default ManageCarousels;
