import { useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ServicesTable from "./ServicesTable";

function AdminServices() {
  useEffect(() => {}, []);
  return (
    <div className='row'>
      <div className='col-lg-12'>
        <Tabs>
          <div className='row row--30 align-items-center '>
            <div className='col-lg-12'>
              <div className='rn-default-tab style-three'>
                <div className='tab-button-panel'>
                  <TabList className='tab-button'>
                    <Tab>
                      <div className='rn-tab-button'>
                        <button>Manage Services</button>
                      </div>
                    </Tab>
                  </TabList>
                </div>
                <div className='tab-content-panel'>
                  <TabPanel>
                    <div className='col-lg-12 p-5'>
                      <div className='row align-items-center'>
                        <ServicesTable></ServicesTable>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </Tabs>
      </div>
    </div>
  );
}

export default AdminServices;
