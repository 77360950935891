// TODO: REFACTOR THE WHOLE FILE

import Modal from "react-bootstrap/Modal";
import {useState} from "react";
import {FiArrowRight} from "react-icons/fi";
import {updatePartner} from "../../../../service/publicServices";


function EditModal(props) {

    const [IsSpinner, setIsSpinner] = useState(false);

    const fetchData=props.fetchData;

    const [partnerName, setPartnerName] = useState(props?.modaldata?.original.name);
    const onChangeName = e => {
        setPartnerName(e.target.value);
    };

    const [order, setOrder] = useState(props?.modaldata?.original.order);
    const onChangeOrder = e => {
        setOrder(e.target.value);
    };

    const [url, setUrl] = useState(props?.modaldata?.original.url);
    const onChangeUrl = e => {
        setUrl(e.target.value);
    };
    const [file, setFile] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(props?.modaldata?.original.imageUrl);

    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            setFile(file);
            setImagePreviewUrl(reader.result);
        };

        reader.readAsDataURL(file);
    };


    const prepareData = () => {
        const finalOrder = order !== undefined ? order : props?.modaldata?.original.order;
        const finalName = partnerName !== undefined ? partnerName : props?.modaldata?.original.name;
        const finalUrl = url !== undefined ? url : props?.modaldata.original.url;
        return {
            id: props?.modaldata?.original.id,
            order: Number(finalOrder),
            name: finalName,
            url: finalUrl
        };
    };

    const Result = myResultData => {
        var className1 = "";
        if (myResultData.myResultData.code === "1") {
            className1 = "success-message";
        } else {
            className1 = "error-message";
        }

        return <p className={`${className1}`}>{`${myResultData.myResultData.description}`}</p>;
    };

    const [result, setResult] = useState(false);
    setTimeout(() => {
        setResult(false);
    }, 10000);

    const saveChanges = e => {
        e.preventDefault();
        console.log(prepareData())

        const data = new FormData();
        if (file) {
            data.append('file', file);
        }
        data.append('request', JSON.stringify(prepareData()));

        updatePartner(data).then(r => {
   if(r.data.code==='1')
       fetchData();
        }).catch()

        setIsSpinner(true);
    };
    return (
        <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered
               className='modal-custom-admin'>
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                    <p className='modal-subtitle'> Edit : {props?.modaldata?.original.name} </p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className={`pt-3`} action='' onSubmit={saveChanges}>
                    <div className='row mb-5 ps-5'>
                        <div className='col-lg-2'>
                            {imagePreviewUrl &&
                                <img src={imagePreviewUrl} alt="Carousel Preview"
                                     style={{width: '500px', height: 'auto'}}/>
                            }
                            {!imagePreviewUrl &&
                                <img src={props?.modaldata?.original.imageUrl} alt="Carousel Preview"
                                     style={{width: '500px', height: 'auto'}}/>
                            }
                        </div>
                        <div className='col-lg-8 pt-5'>
                            <input className='form-control' type='file' onChange={handleImageChange}></input>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label htmlFor="partnerName">Order</label>
                                <input
                                    type='number'
                                    name='expertName'
                                    placeholder='Order'
                                    required
                                    onChange={onChangeOrder}
                                    defaultValue={props?.modaldata?.original.order}
                                />
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label for="partnerName">Partner Name</label>
                                <input
                                    type='text'
                                    name='partnerName'
                                    placeholder='Partner Name'
                                    required
                                    onChange={onChangeName}
                                    defaultValue={props?.modaldata?.original.name}
                                />
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label htmlFor="partnerName">Website</label>
                                <input
                                    type='text'
                                    name='partnerName'
                                    placeholder='Partner Name'
                                    required
                                    onChange={onChangeUrl}
                                    defaultValue={props?.modaldata?.original.url}
                                />
                            </div>
                        </div>
                    </div>


                    <div className='row ps-5'>
                        <div className='form-group  col-xl-6'>
                            <button className='btn-default btn-small'>
                                Save
                                <i className='icon ms-3'>
                                    {IsSpinner ? (
                                        <span className='spinner-border spinner-border-sm' role='status'
                                              aria-hidden='true'></span>
                                    ) : (
                                        <FiArrowRight/>
                                    )}
                                </i>
                            </button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default EditModal;
