import { LOGIN_SUCCESS, LOGIN_FAIL, SET_MESSAGE, LOGOUT, CLEAR_MESSAGE, REFRESH_TOKEN } from "./type";
import AuthService from "../service/auth";

export const login = (email, password) => dispatch => {
  return AuthService.login(email, password).then(
    data => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
      localStorage.setItem("accessToken", data.accessToken); // Store the access token in localStorage
      localStorage.setItem("refreshToken", data.refreshToken); // Store the refresh token in localStorage
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject(error.message);
    },
  );
};

export const logout = () => dispatch => {
  AuthService.logout();
  localStorage.removeItem("accessToken"); // Remove the access token from localStorage
  localStorage.removeItem("refreshToken"); // Remove the refresh token from localStorage
  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: CLEAR_MESSAGE,
  });
};

export const refreshToken = () => dispatch => {
  const refreshToken = localStorage.getItem("refreshToken");

  // Retrieve the refresh token from localStorage
  // Make the necessary API call or logic to refresh the token using the refreshToken
  // Once you receive the new access token, dispatch the REFRESH_TOKEN action to update it in the state

  return AuthService.refreshToken(refreshToken).then(
    newAccessToken => {
      dispatch({
        type: REFRESH_TOKEN,
        payload: newAccessToken,
      });
    },
    error => {
      //    todo: HANDLE ERROR
      return Promise.reject(error.message);
    },
  );
};
