import { useState,useEffect, useMemo } from "react";
import EditModal from "./EditModal";
import DataTable from "../../components/DataTable/DataTable";
import ExpertsList from "../../../../data/experts/experts.json";
import { getAllExpertList, deleteExpert } from "../../../../service/publicServices";

function ExpertTable() {

  const [showExperEditModal, setshowExperEditModal] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [expertList, setExpertList] = useState([]);
  const [rowCount, setrowCount] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [rowUrl, setRowUrl] = useState(null);

  const totalPages = Math.ceil(rowCount / 10);
  const fetchData = async () => {
    try {
      const data = await getAllExpertList(prepareData());
      if (data?.data?.experts) {
        console.log(data?.data?.experts);
        setExpertList(data.data.experts);
        setrowCount(data.data.rowCount || 0);
      } else {
      }
    } catch (error) {
      //    TODO: HANDLE ERROR
    }
  };
  useEffect(() => {
    fetchData();
  }, [pageIndex]);

  useEffect(() => { }, [expertList]);

  const prepareData = () => {
    return {
      page: pageIndex + 1,
      lang: "en",
    };
  };

  const handlePageChange = newPageIndex => {
    const clampedPageIndex = Math.max(0, Math.min(newPageIndex, totalPages - 1));
    setPageIndex(clampedPageIndex);
  };

    const deleteRow = row => {
    const delJson = { url: row?.original?.url };
    const formData = new FormData();
    formData.append("request", JSON.stringify(delJson));

    deleteExpert(formData)
      .then(response => {
        fetchData();
      })
      .catch(error => {});
  }; 

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Image",
        accessor: "imageUrl",
        disableSort: true,
      },
      {
        Header: "Expert Name",
        accessor: "name",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableSort: true,
      },
    ],
    [],
  );
  
  return (
    <>
      <div className='row col-lg-12'>
        <DataTable
          data={expertList}
          columns={columns}
          sortable={true}
          setRowData={setRowData}
          setshowEditModal={setshowExperEditModal}
          pageIndex={pageIndex}
          onPageChange={handlePageChange}
          totalPages={totalPages}
          deleteRow={deleteRow}
        />
      </div>
      <EditModal show={showExperEditModal} modaldata={rowData} onHide={() => setshowExperEditModal(false)}></EditModal>
    </>
  );
}

export default ExpertTable;
