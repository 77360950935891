// TODO: REFACTOR THE WHOLE FILE

import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { addServiceTranslation } from "../../../../service/publicServices";
import { FiArrowRight } from "react-icons/fi";
import CkEditor from "../../components/Editor/CkEditor";
import Select from "../../components/select/Select";
import IndustryList_en from "../../../../data/search/industries.json";
import IndustryList_bd from "../../../../data/search/industries.bd.json";
import IndustryList_de from "../../../../data/search/industries.de.json";
import IndustryList_tr from "../../../../data/search/industries.tr.json";
import BenefitsList_en from "../../../../data/search/benefits.json";
import BenefitsList_bd from "../../../../data/search/benefits.bd.json";
import BenefitsList_de from "../../../../data/search/benefits.de.json";
import BenefitsList_tr from "../../../../data/search/benefits.tr.json";

function AddLangModal(props) {


  const [IsSpinner, setIsSpinner] = useState(false);
  const [resultData, setResultData] = useState(null);

  let IndustryList;
  let BenefitsList;

  switch (props?.modaldata?.lang) {
    case "en":
      IndustryList = IndustryList_en;
      BenefitsList = BenefitsList_en;
      break;
    case "bd":
      IndustryList = IndustryList_bd;
      BenefitsList = BenefitsList_bd;
      break;
    case "de":
      IndustryList = IndustryList_de;
      BenefitsList = BenefitsList_de;
      break;
    case "tr":
      IndustryList = IndustryList_tr;
      BenefitsList = BenefitsList_tr;
      break;
    default:
      IndustryList = IndustryList_en;
      BenefitsList = BenefitsList_en;
      break;
  }

  const [benefitsText, setBenefitsText] = useState(props?.modaldata?.benefits_text);
  const onChangeBenefitsText = e => {
    setBenefitsText(e.target.value);
  };

  const [description, setDescription] = useState(props?.modaldata?.description);
  const onChangeDescription = e => {
    setDescription(e.target.value);
  };


  const [enquiryText, setEnquiryText] = useState(props?.modaldata?.enquiryText);
  const onChangeEnquiryText = e => {
    setEnquiryText(e.target.value);
  };

  const [questionText, setQuestionText] = useState(props?.modaldata?.questionText);
  const onChangeQuestionText = e => {
    setQuestionText(e.target.value);
  };

  const [shortText, setShortText] = useState(props?.modaldata?.shortText);
  const onChangeShortText = e => {
    setShortText(e.target.value);
  };
  const [text, setText] = useState(props?.modaldata?.text);
  const onChangeText = e => {
    setText(e.target.value);
  };

  const [title, setTitle] = useState(props?.modaldata?.title);
  const onChangeTitle = e => {
    setTitle(e.target.value);
  };

  const [titleSection2, setTitleSection2] = useState(props?.modaldata?.title_section_2);
  const onChangeTitleSection2 = e => {
    setTitleSection2(e.target.value);
  };

  const [seoTitle, setSeoTitle] = useState(props?.modaldata?.seoTitle);
  const onChangeSeoTitle = e => {
    setSeoTitle(e.target.value);
  };

  const [ogTitle, setOgTitle] = useState(props?.modaldata?.ogTitle);
  const onChangeOgTitle = e => {
    setOgTitle(e.target.value);
  };

  const [metaDescription, setMetaDescription] = useState(props?.modaldata?.metaDescription);
  const onChangeMetaDescription = e => {
    setMetaDescription(e.target.value);
  };

  const [twtitle, setTwTitle] = useState(props?.modaldata?.twTitle);
  const onChangeTwTitle = e => {
    setTwTitle(e.target.value);
  };

  const [twDescription, setTwDescription] = useState(props?.modaldata?.twDescription);
  const onChangeTwDescription = e => {
    setTwDescription(e.target.value);
  };

  const [twImageAlt, setTwImageAlt] = useState(props?.modaldata?.twImageAlt);
  const onChangeTwImageAlt = e => {
    setTwImageAlt(e.target.value);
  };

  const [industry, setindustry] = useState(null);
  const onChangeIndustry = selectedOption => {
    setindustry(selectedOption);
  };

  const [benefits, setBenefits] = useState(null);
  const onChangeBenefits = selectedOption => {
    setBenefits(selectedOption);
  };


  const prepareData = () => {
    const formData = new FormData();

    let dataBenefitsText = benefitsText ? benefitsText : props?.modaldata?.benefits_text;
    let dataDescription = description ? description : props?.modaldata?.description;
    let dataEnquiryText = enquiryText ? enquiryText : props?.modaldata?.enquiryText;
    let dataQuestionText = questionText ? questionText : props?.modaldata?.questionText;
    let dataShortText = shortText ? shortText : props?.modaldata?.shortText;
    let dataText = text ? text : props?.modaldata?.text;
    let dataTitle = title ? title : props?.modaldata?.title;
    let dataTitleSection2 = titleSection2 ? titleSection2 : props?.modaldata?.title_section_2;
    let dataSeoTitle = seoTitle ? seoTitle : props?.modaldata?.seoTitle;
    let dataOgTitle = ogTitle ? ogTitle : props?.modaldata?.ogTitle;
    let datatmetaDescription = metaDescription ? metaDescription : props?.modaldata?.metaDescription;
    let datatwtitle = twtitle ? twtitle : props?.modaldata?.twTitle;
    let datatwDescription = twDescription ? twDescription : props?.modaldata?.twDescription;
    let datatwImageAlt = twImageAlt ? twImageAlt : props?.modaldata?.twImageAlt;
    let dataIndustry = industry ? industry : props?.modaldata?.industry;
    let databenefits = benefits ? benefits : props?.modaldata?.benefits;

    const data = {
      url: props?.rowurl,
      service: {
        langData: [
          {
            benefits_text: dataBenefitsText,
            description: dataDescription,
            enquiryText: dataEnquiryText,
            questionText: dataQuestionText,
            shortText: dataShortText,
            text: dataText,
            title: dataTitle,
            title_section_2: dataTitleSection2,
            seoTitle: dataSeoTitle,
            ogTitle: dataOgTitle,
            metaDescription: datatmetaDescription,
            twTitle: datatwtitle,
            twDescription: datatwDescription,
            twImageAlt: datatwImageAlt,
            lang: props?.modaldata?.lang,
            industry: dataIndustry,
            benefits: databenefits
          },
        ],
      },
    };
    formData.append("request", JSON.stringify(data));


    return formData;
  };

  const Result = myResultData => {
    var className1 = "";
    if (myResultData.myResultData.code === "1") {
      className1 = "success-message";
    } else {
      className1 = "error-message";
    }

    return <p className={`${className1}`}>{`${myResultData.myResultData.description}`}</p>;
  };

  const [result, setResult] = useState(false);
  setTimeout(() => {
    setResult(false);
  }, 10000);

  const addServiceLang = e => {
    e.preventDefault();
    setIsSpinner(true);

    const formData = prepareData();

    addServiceTranslation(formData).then(
      result => {
        setIsSpinner(false);
        setResult(true);
        setResultData(result.data);
      },
      error => {
        setIsSpinner(false);
        setResultData(result.data);
      },
    );
    e.target.reset();
  };
  return (
    <Modal {...props} size='xl' aria-labelledby='contained-modal-title-vcenter' centered className='modal-custom-admin'>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <p className='modal-subtitle'>
            {" "}
            Edit : {props?.modaldata?.title} Lang : {props?.modaldata?.lang}{" "}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className='pt-3 ' action='' onSubmit={addServiceLang}>
          <div className='row col-lg-12'>
            <div className="col-lg-6">
              <div className='form-group'>
                <label className='form-label'>Benefits Text</label>
                <input
                  type='text'
                  name='benefitsText'
                  placeholder='benefitsText...'
                  required
                  onChange={onChangeBenefitsText}
                  defaultValue={props?.modaldata?.benefits_text}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className='form-group'>
                <label className='form-label'>Enquiry Text</label>
                <input
                  type='text'
                  name='enquiryText'
                  placeholder='enquiryText...'
                  required
                  onChange={onChangeEnquiryText}
                  defaultValue={props?.modaldata?.enquiryText}
                />
              </div>
            </div>
          </div>
          <div className='row col-lg-12'>
            <div className="col-lg-6">
              <div className='form-group'>
                <label className='form-label'>Question Text</label>
                <input
                  type='text'
                  name='questionText'
                  placeholder='questionText...'
                  required
                  onChange={onChangeQuestionText}
                  defaultValue={props?.modaldata?.questionText}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className='form-group'>
                <label className='form-label'>Short Text</label>
                <input
                  type='text'
                  name='shortText'
                  placeholder='shortText...'
                  required
                  onChange={onChangeShortText}
                  defaultValue={props?.modaldata?.shortText}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className='form-group'>
              <label className='form-label'>Text</label>
              <input
                type='text'
                name='text'
                placeholder='text...'
                required
                onChange={onChangeText}
                defaultValue={props?.modaldata?.text}
              />
            </div>
          </div>
          <div className='row col-lg-12'>
            <div className="col-lg-6">
              <div className='form-group'>
                <label className='form-label'>Title</label>
                <input
                  type='text'
                  name='title'
                  placeholder='title...'
                  required
                  onChange={onChangeTitle}
                  defaultValue={props?.modaldata?.title}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className='form-group'>
                <label className='form-label'>Title Section 2</label>
                <input
                  type='text'
                  name='titleSection2'
                  placeholder='titleSection...'
                  required
                  onChange={onChangeTitleSection2}
                  defaultValue={props?.modaldata?.title_section_2}
                />
              </div>
            </div>
          </div>
          <div className='row col-lg-12'>
            <div className="col-lg-6">
              <div className='form-group'>
                <label className='form-label'>Seo Title</label>
                <input
                  type='text'
                  name='seoTitle'
                  placeholder='seoTitle...'
                  required
                  onChange={onChangeSeoTitle}
                  defaultValue={props?.modaldata?.seoTitle}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className='form-group'>
                <label className='form-label'>Og Title</label>
                <input
                  type='text'
                  name='ogTitle'
                  placeholder='ogTitle...'
                  required
                  onChange={onChangeOgTitle}
                  defaultValue={props?.modaldata?.ogTitle}
                />
              </div>
            </div>
          </div>
          <div className='row col-lg-12'>
            <div className="col-lg-6">
              <div className='form-group'>
                <label className='form-label'>Tw Title</label>
                <input
                  type='text'
                  name='twTitle'
                  placeholder='twTitle...'
                  required
                  onChange={onChangeTwTitle}
                  defaultValue={props?.modaldata?.twTitle}
                />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='form-label'>Tw Description</label>
                <input
                  type='text'
                  name='twDescription'
                  placeholder='twDescription...'
                  required
                  onChange={onChangeTwDescription}
                  defaultValue={props?.modaldata?.twDescription}
                />
              </div>
            </div>
          </div>
          <div className='row col-lg-12'>
            <div className="col-lg-6">
              <div className='form-group'>
                <label className='form-label'>Tw Image Alt</label>
                <input
                  type='text'
                  name='twImageAlt'
                  placeholder='twImageAlt...'
                  required
                  onChange={onChangeTwImageAlt}
                  defaultValue={props?.modaldata?.twImageAlt}
                />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='form-label'>Meta Description</label>
                <input
                  type='text'
                  name='metaDescription'
                  placeholder='metaDescription...'
                  required
                  onChange={onChangeMetaDescription}
                  defaultValue={props?.modaldata?.metaDescription}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className='form-group'>
              <label className='form-label'>Description</label>
              <CkEditor name='content' editorData={props?.modaldata?.description} onChange={onChangeDescription}></CkEditor>
            </div>
          </div>

          <div className="col-lg-12">
            <div className='form-group'>
              <label className='form-label'>Industry</label>
              <Select
                pHolder='Industry / Sector Serve'
                options={IndustryList}
                name='industry'
                onValueChange={onChangeIndustry}
                isMulti={true}></Select>
            </div>
          </div>
          <div className="col-lg-12">
            <div className='form-group'>
              <label className='form-label'>Benefits</label>
              <Select
                pHolder='Benefits'
                options={BenefitsList.benefits}
                name='benefits'
                onValueChange={onChangeBenefits}
                isMulti={true}></Select>
            </div>
          </div>
          <div className='row ps-5'>
            <div className='form-group col-xl-4'>
              <button className='btn-default btn-small'>
                Save
                <i className='icon ms-3'>
                  {IsSpinner ? (
                    <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
                  ) : (
                    <FiArrowRight />
                  )}
                </i>
              </button>
            </div>
            <div className='form-group'>{result ? <Result myResultData={resultData} /> : null}</div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddLangModal;
