import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { updateVectraResource } from "../../../../service/publicServices";
import Select from "../../components/select/Select";
import countryList from "../../../../data/search/country.json"
// TODO:REFACTOR

function EditModal(props) {

  const options = [
    { value: "x", label: "Blog" },
    { value: "p", label: "Press Release" },
    { value: "a", label: "Article" },
  ];

  const ResourceType = {
    value: `${props?.modaldata?.original.type}`,
    label: `${props?.modaldata?.original.typeName}`,
  };
  const selectedCountryList = props?.modaldata?.original.country?.map(item => ({
    value: item.id,
    label: item.name,
  }));
  
  const [IsSpinner, setIsSpinner] = useState(false);

  const [image, setImage] = useState(null);
  const [url, setUrl] = useState(null);
  const [resultData, setResultData] = useState(null);

  const onChangeUrl = e => {
    setUrl(e.target.value);
  };

  const [type, setType] = useState(null);
  const onChangeType = e => {
    setType(e);
  };

  const [country, setCountry] = useState(null);
  const onChangeCountry = e => {
    setCountry(e);
  };

  const prepareData = () => {
    const formData = new FormData();
    let dataUrl = url ? url : props?.modaldata?.original.url;
    let dataType = type ? type.value : props?.modaldata?.original.type;
    let dataCountry = country ? country : props?.modaldata?.original.country;

    const data = {
      url: props?.modaldata?.original.url,
      resource: {
        url: dataUrl,
        type: dataType,
        country: dataCountry,
        cid: props?.modaldata?.original.cid,
        id: props?.modaldata?.original.id,
        imgUrl: props?.modaldata?.original.imgUrl,
        status: "1",
      },
    };
    if (country) {
      data.resource.country = data.resource.country.map(c => ({
        id: c.value,
        name: c.label,
        shortCode: c.shortCode,
      }));
    }else if(dataCountry){
      data.resource.country = dataCountry.map (c=> ({
        id:c.id,
        name:c.name,
        shortCode:c.shortCode
      }))
    }
    formData.append("request", JSON.stringify(data));
    formData.append("file", image);
    return formData;
  };


  const Result = myResultData => {
    var className1 = "";
    if (myResultData.myResultData.code === "1") {
      className1 = "success-message";
    } else {
      className1 = "error-message";
    }

    return <p className={`${className1}`}>{`${myResultData.myResultData.description}`}</p>;
  };

  const [result, setResult] = useState(false);
  setTimeout(() => {
    setResult(false);
  }, 10000);

  const saveChanges = e => {
    e.preventDefault();
    setIsSpinner(true);
    updateVectraResource(prepareData()).then(
      result => {
        setIsSpinner(false);
        setResult(true);
        setResultData(result.data);
      },
      error => {
        setIsSpinner(false);
        setResultData(result.data);
      },
    );
    e.target.reset();
  };

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered className='modal-custom-admin'>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <p className='modal-subtitle'> Edit : {props?.modaldata?.original.title} </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className='pt-3 ' action='' onSubmit={saveChanges}>
          <div className='col-lg-12'>
            <div className='form-group'>
              <label className='form-label'>Resource Type</label>
              <Select
                pHolder='Resource Type...'
                options={options}
                name='resourceType'
                isMulti={false}
                selectedvalue={ResourceType}
                onValueChange={onChangeType}></Select>
            </div>
          </div>
          <div className='form-group'>
            <label className='form-label'>Country</label>
            <Select
              pHolder='Country'
              options={countryList}
              name='country'
              isMulti={true}
              selectedvalue={selectedCountryList}
              onValueChange={onChangeCountry}></Select>
          </div>
          <div className='col-lg-12'>
            <div className='form-group'>
              <label className='form-label'>url</label>
              <input
                type='text'
                name='url'
                placeholder='url...'
                required
                onChange={onChangeUrl}
                defaultValue={props?.modaldata?.original.url}
              />
            </div>
          </div>

          <div className='row mb-5 ps-5'>
            <div className='col-lg-2'>
              <img src={props?.modaldata?.original.imgUrl} alt='expert' width='80' height='80' />
            </div>
            <div className='col-lg-8'>
              <label className='form-label'>Select Image</label>
              <input className='form-control' type='file' name='author' placeholder='Author' ></input>
            </div>
          </div>
          <div className='row ps-5'>
            <div className='form-group  col-xl-4'>
              <button className='btn-default btn-small'>
                Save
                <i className='icon ms-3'>
                  {IsSpinner ? (
                    <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
                  ) : (
                    <FiArrowRight />
                  )}
                </i>
              </button>
            </div>
            <div className='form-group'>{result ? <Result myResultData={resultData} /> : null}</div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default EditModal;