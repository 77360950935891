import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { FiArrowRight } from "react-icons/fi";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
import CkEditor from "../../components/Editor/CkEditor";

// const MySwal = withReactContent(Swal);

function EditModal(props) {
  const [IsSpinner, setIsSpinner] = useState(false);

  const [checked, setChecked] = useState(`${props?.modaldata?.original.ctaButton.name}`);

  const handleChange = () => {
    setChecked(!checked);
  };
  const [checkedBanner, setcheckedBanner] = useState(`${props?.modaldata?.original.carouselsStatus}`);

  const handleBannerChange = () => {
    setcheckedBanner(!checkedBanner);
  };

  const prepareData = () => {
    return {};
  };
  const saveCarousel = e => {
    e.preventDefault();
    setIsSpinner(true);

    /* submitEnquiry(prepareData())
      .then((result) => {

        setIsSpinner(false);
        //setResult(true);
        if (result.data.code === '1') {
          MySwal.fire({
            confirmButtonColor: "#EC726B",
            title: <strong>Success !</strong>,
            html: <i>{result.data.description}</i>,
            icon: 'success'
          })
        } else {
          MySwal.fire({
            confirmButtonColor: "#EC726B",
            title: <strong>Error !</strong>,
            html: <i>{result.data.description}</i>,
            icon: 'error'
          })
        }
      },
        (error) => {

          setIsSpinner(false);
          MySwal.fire({
            confirmButtonColor: "#EC726B",
            title: <strong>Error !</strong>,
            html: <i>{error.data}</i>,
            icon: 'error'
          })
        }
      );
    e.target.reset(); */
  };
  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered className='modal-custom-admin'>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <p className='modal-subtitle'> Edit : {props?.modaldata?.original.name} </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className='pt-3 ' action='' onSubmit={saveCarousel}>
          <div className='form-group'>
            <label className='form-label'>Select Image</label>
            <input className='form-control' type='file' name='author' placeholder='Author' required />
          </div>
          <div className='form-group'>
            <label className='form-label'>Heading</label>
            <CkEditor name='about' editorData={props?.modaldata?.original.heading}></CkEditor>
          </div>
          <div className='form-group'>
            <label className='form-label'>Description</label>
            <CkEditor name='about' editorData={props?.modaldata?.original.description}></CkEditor>
          </div>

          <div className='form-group'>
            <input type='checkbox' id='checkedBanner' checked={checkedBanner} onChange={handleBannerChange}></input>
            <label htmlFor='checkbox'>Banner Is Active</label>
          </div>
          <div className='row'>
            <div className='col-lg-3'>
              <div className='form-group'>
                <input
                  type='text'
                  name='ctaBtnName'
                  placeholder='ctaBtnName'
                  defaultValue={props?.modaldata?.original.ctaButton.name}
                />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <input
                  type='text'
                  name='ctaBtnUrl'
                  placeholder='ctaBtnUrl'
                  defaultValue={props?.modaldata?.original.ctaButton.url}
                />
              </div>
            </div>
            <div className='col-lg-3 pt-3'>
              <div className='form-group'>
                <input type='checkbox' id='checkboxCtaBtn' checked={checked} onChange={handleChange}></input>
                <label htmlFor='checkbox'>Button Is Active</label>
              </div>
            </div>
          </div>

          <div className='row ps-5'>
            <div className='form-group  col-xl-4'>
              <button className='btn-default btn-small'>
                Save
                <i className='icon ms-3'>
                  {IsSpinner ? (
                    <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
                  ) : (
                    <FiArrowRight />
                  )}
                </i>
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default EditModal;
