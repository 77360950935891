// // ** I18n Imports
// import i18n from 'i18next'
// import Backend from 'i18next-xhr-backend'
// import { initReactI18next } from 'react-i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'

// i18n
//     // Enables the i18next backend
//     .use(Backend)
//     // Enable automatic language detection
//     .use(LanguageDetector)
//     // Enables the hook initialization module
//     .use(initReactI18next)
//     .init({
//         lng: 'en',
//         backend: {
//             /* translation file path */
//             loadPath: `${process.env.PUBLIC_URL}/assets/data/locales/{{lng}}.json`
//         },
//         fallbackLng: 'en',
//         compatibilityJSON: 'v3',
//         debug: false,
//         escapeValue: false
//     })

// export default i18n

// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import HttpBackend from "i18next-http-backend"; // Use i18next-http-backend instead of i18next-xhr-backend

// i18n
//   .use(HttpBackend) // Use the HttpBackend for server-side rendering
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     lng: "en",
//     backend: {
//       loadPath: `${process.env.PUBLIC_URL}/assets/data/locales/{{lng}}.json`,
//     },
//     fallbackLng: "en",
//     compatibilityJSON: "v3",
//     debug: false,
//     escapeValue: false,
//   });

// export default i18n;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import your translation files
import enTranslation from "../../locales/en.json";
import deTranslation from "../../locales/de.json";
import trTranslation from "../../locales/tr.json";
import bdTranslation from "../../locales/bd.json";

// Configure i18next
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    de: { translation: deTranslation },
    tr: { translation: trTranslation },
    bd: { translation: bdTranslation },
    // Add other language resources here
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
