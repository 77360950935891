import React from 'react'
import { Link } from "react-router-dom";

import {useTranslation} from "react-i18next";
import i18n from "../../configs/i18n/index";


const CopyrightAdmin = () => {

    const { t } = useTranslation();

    return (
        <div className="copyright-area copyright-style-one copyright-admin">
            <div className="container">
                <div className="row align-items-center">

                    <div className="col-lg-12 col-md-4 col-sm-12 col-12">
                        <div className="copyright-right text-center ">
                            <p className="copyright-text">© {new Date().getFullYear()} <strong>VECTRA International</strong> | {t('vb.arr')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CopyrightAdmin;