import {useState, useMemo, useEffect} from "react";
import EditModal from "./EditModal";

import {deletePartner, getPartners} from "../../../../service/publicServices";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import DataTable from "./DataTable";

const MySwal = withReactContent(Swal);

//import partners from "../../../../data/card/partnerCard.json";

function PartnersTable() {
    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
            },
            {
                Header: "Order",
                accessor: "order",
            },
            {
                Header: "Image",
                accessor: "imageUrl",
                disableSort: true,
            },
            {
                Header: "Partner Name",
                accessor: "name",
            },
            {
                Header: "Website",
                accessor: "url",
            },
            {
                Header: "Actions",
                accessor: "actions",
            },
        ],
        [],
    );

    const [partners, setPartners] = useState(null)

    const fetchData = () => {
        getPartners().then(
            result => {
                if (result.data.code === '1') {
                    setPartners(result.data.partners)
                } else {
                    MySwal.fire({
                        confirmButtonColor: "#EC726B",
                        title: <strong>Error !</strong>,
                        html: <i>{result.data.description}</i>,
                        icon: "error",
                    });
                }
            }
        )
    }

    useEffect(() => {

    fetchData();

    }, [])
    const prepareDeleteData = (rowId) => {
        return {
            partnerId: rowId,
        };
    };
    const deleteRow = (row) => {

        deletePartner(prepareDeleteData(row?.original?.id)).then(
            (result) => {
                if (result.data.code === "1") {
                    MySwal.fire({
                        confirmButtonColor: "#EC726B",
                        title: <strong>Success !</strong>,
                        html: <i>{result.data.description}</i>,
                        icon: "success",
                    });
                    fetchData(); // Refresh data after successful delete
                } else {
                    MySwal.fire({
                        confirmButtonColor: "#EC726B",
                        title: <strong>Error !</strong>,
                        html: <i>{result.data.description}</i>,
                        icon: "error",
                    });
                }
            },
            (error) => {
                MySwal.fire({
                    confirmButtonColor: "#EC726B",
                    title: <strong>Error !</strong>,
                    html: <i>{error.data}</i>,
                    icon: "error",
                });
            }
        );
    }

    const [showEditModal, setShowEditModal] = useState(false);
    const [rowData, setRowData] = useState(null);
    return (
        <>
            <div className='row col-lg-12'>
                {partners &&
                    <DataTable
                        data={partners}
                        columns={columns}
                        sortable={true}
                        setRowData={setRowData}
                        setShowEditModal={setShowEditModal}
                        deleteRow={deleteRow}
                    />
                }
            </div>
            <EditModal show={showEditModal}
                       modaldata={rowData}
                       fetchData={fetchData}
                       onHide={() => setShowEditModal(false)}></EditModal>
        </>
    );
}

export default PartnersTable;
