export const getSessionData = () => {
  const sessionData = sessionStorage.getItem("session");
  return sessionData ? JSON.parse(sessionData) : null;
};

export const setSessionData = data => {
  sessionStorage.setItem("session", JSON.stringify(data));
};

export const clearSessionData = () => {
  sessionStorage.removeItem("session");
};
