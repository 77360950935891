import ClientsTable from "./ClientsTable";

function ManageClients() {
  return (
    <>
      <div className='row col-lg-10'>
        <ClientsTable></ClientsTable>
      </div>
    </>
  );
}

export default ManageClients;
