import api from "./api";

 //STB
// const baseURL = "https://vectra-international-stb-core.azurewebsites.net";
// const searchBaseUrl = "https://vectra-stb-search-service.azurewebsites.net";


 //PROD
const baseURL = "https://vectra-international-core.azurewebsites.net";
const searchBaseUrl = "https://vectra-dach-search-service.azurewebsites.net";


//local
//  const baseURL = baseURL;
// const searchBaseUrl = "https://vectra-stb-search-service.azurewebsites.net";

export const subscribeNewsletter = async body => {
  return api.post(baseURL + "/public/main/subscribe/newsletter", body);
};
export const downloadTeaserRequest = async body => {
  return api.post(baseURL + "/public/main/download/teaser", body);
};
export const submitEnquiry = async body => {
  return api.post(baseURL + "/public/main/contact", body);
};

export const createJobApp = body => {
  return api.post(baseURL + "/public/main/applyNow", body);
};

export const getVectraResource = body => {
  return api.post(baseURL + "/public/main/resource/getResource", body);
};

export const getResourceList = body => {
  return api.post(baseURL + "/public/main/resource/getAllResourcesByPage", body);
};

export const getListOfResourcesByPage = body => {
  return api.post(baseURL + "/public/main/resource/getListOfResourcesByPage", body);
};

export const getListOfResourcesByType = body => {
  return api.post(baseURL + "/public/main/resource/getResourcesByType", body);
};

export const updateVectraResourceDetail = body => {
  return api.post(baseURL + "/private/main/updateVectraResourceDetail", body);
};

export const createVectraResourceDetail = body => {
  return api.post(baseURL + "/private/main/createResource", body);
};

export const updateVectraResource = body => {
  return api.post(baseURL + "/private/main/updateVectraResource", body);
};

export const addResourceTranslation = body => {
  return api.post(baseURL + "/private/main/addResourceTranslation", body);
};

export const deleteVectraResource = body => {
  return api.post(baseURL + "/private/main/deleteVectraResource", body);
};

export const searchResources = body => {
  return api.post(searchBaseUrl + "/search/resource", body);
};

export const getOpenPositions = () => {
  return api.get(baseURL + "/public/main/getOpenPositions");
};

//Services
export const getAllServicesList = body => {
  return api.post(baseURL + "/public/main/services/getAll", body);
};

export const updateVectraServiceDetail = body => {
  return api.post(baseURL + "/private/main/updateVectraServiceDetail", body);
};

export const updateVectraService = body => {

  return api.post(baseURL + "/private/main/updateVectraService", body);
};

export const addServiceTranslation = body => {
  return api.post(baseURL + "/private/main/addServiceTranslation", body);
};

//Experts
export const getAllExpertList = body => {
  return api.post(baseURL + "/public/main/experts/getAll", body);
};

export const createExpert = body => {
  return api.post(baseURL + "/private/main/createExpert", body);
};

export const updateExpert = body => {
  return api.post(baseURL + "/private/main/updateVectraExpert", body);
};

export const deleteExpert = body => {
  return api.post(baseURL + "/private/main/deleteVectraExpert", body);
};

export const createOpenPosition = body => {
  const openPosition = { openPosition: body };
  return api.post(baseURL + "/private/main/createOpenPosition", openPosition);
};
export const updateOpenPosition = body => {
  const openPosition = { openPosition: body };
  return api.post(baseURL + "/private/main/updateOpenPosition", openPosition);
};
export const deleteOpenPosition = body => {

  return api.post(baseURL + "/private/main/deleteOpenPosition", body);
};

export const getPartners = () =>{
  return api.get(baseURL+"/public/main/partners/getAll")
}

export const getClients = () =>{
  return api.get(baseURL+"/public/main/clients/getAll")
}


export const createMainPageCarousel = body => {
  return api.post(baseURL + "/private/main/createUpdateMainPageCarousel", body,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const createPartner = body => {
  return api.post(baseURL + "/private/main/createPartner", body,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const createClient = body => {
  return api.post(baseURL + "/private/main/createClient", body,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const updatePartner = body => {
  return api.post(baseURL + "/private/main/updatePartner", body,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
export const updateClient = body => {
  return api.post(baseURL + "/private/main/updateClient", body,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};


export const getMainPageCarousels = () => {
  return api.get(baseURL +'/public/main/getMainPageCarousels');
};

export const fetchTestimonials = () => {
  return api.get(baseURL +'/public/main/getTestimonials');
};

export const createUpdateTestimonial = body => {
  return api.post(baseURL + "/private/main/createUpdateTestimonial", body);
};
export const deleteTestimonial = (body) => {
  return api.post(baseURL + "/private/main/deleteTestimonial",body);
};

export const deletePartner = (body) => {
  return api.post(baseURL + "/private/main/deletePartner",body);
};

export const deleteClient = (body) => {
  return api.post(baseURL + "/private/main/deleteClient",body);
};