import {useRef, useState} from "react";
//import Select from "../../../../components/dropDown/DropDown";
import Select from "../../components/select/Select";
import {FiArrowRight} from "react-icons/fi";
import {createVectraResourceDetail} from "../../../../service/publicServices";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CkEditor from "../../components/Editor/CkEditor";
import {setPreviewImage} from "../../../../service/resourceStorage";
import {updatePreviewResourceData} from "../../../../actions/resourcePreviewAction";
import isBrowserEnv from "../../../../utils/isBrowserEnv";
import countryList from "../../../../data/search/country.json"

const MySwal = withReactContent(Swal);

function AddResource(formStyle) {
    const options = [
        {value: "x", label: "Blog"},
        {value: "p", label: "Press Release"},
        {value: "a", label: "Article"},
        {value: "n", label: "Newsletter"},
    ];

    const optionsDisplayType = [
        {value: 0, label: "Regular"},
        {value: 1, label: "Contextual"},
    ];

    const optionsLang = [
        {value: "en", label: "English"},
        {value: "tr", label: "Turkish"},
        {value: "de", label: "German"},
        {value: "bd", label: "Bengladesh"},
    ];
    const inputFile = useRef(null);
    const [IsSpinner, setIsSpinner] = useState(false);
    const [displayType, setDisplayType] = useState(0);
    const [image, setImage] = useState(null);

    const onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            setImage(event.target.files[0]);
        }
    };
    const [date, setDate] = useState(null);
    const onChangeDate = e => {
        setDate(e.target.value);
    };

    const [time, setTime] = useState(null);
    const onChangeTime = e => {
        setTime(e.target.value);
    };

    const [dataTwTitle, setDataTwTitle] = useState(null);
    const onChangeDataTwTitle = e => {
        setDataTwTitle(e.target.value);
    };

    const [dataTwDescription, setDataTwDescription] = useState(null);
    const onChangeDataTwDescription = e => {
        setDataTwDescription(e.target.value);
    };
    const [dataTwImageAlt, setDataTwImageAlt] = useState(null);
    const onChangeDataTwImageAlt = e => {
        setDataTwImageAlt(e.target.value);
    };

    const [dataTitle, setDataTitle] = useState(null);
    const onChangeDataTitle = e => {
        setDataTitle(e.target.value);
    };

    const [dataMetaTitle, setDataMetaTitle] = useState(null);
    const onChangeDataMetaTitle = e => {
        setDataMetaTitle(e.target.value);
    };

    const [dataMetaDescription, setDataMetaDescription] = useState(null);
    const onChangeDataMetaDescription = e => {
        setDataMetaDescription(e.target.value);
    };
    const [resourceTypeName, setResourceTypeName] = useState(null);
    const [resourceType, setResourceType] = useState(null);
    const onChangeResourceType = e => {
        setResourceType(e.value);
        setResourceTypeName(e.label);
    };

    const [paragraphs, setParagraphs] = useState([]);
    const nextId = useRef(0); // Simple counter to generate unique IDs

    const onChangeDisplayType = e => {
        setDisplayType(e.value);
    };

    const [resourceLang, setResourceLang] = useState(null);
    const onChangeResourceLang = e => {
        setResourceLang(e.value);
    };

    const [country, setCountry] = useState(null);
    const onChangeCountry = e => {
        console.log(e);
        setCountry(e);
    };

    const [content, setContent] = useState();
    const onChangeContent = data => {
        setContent(data);
    };

    const [dataUrl, setDataUrl] = useState(null);
    const onChangeDataUrl = e => {
        setDataUrl(e.target.value);
    };

    const prepareData = () => {
        const formData = new FormData();
        const data = {
            resource: {
                url: dataUrl,
                type: resourceType,
                country: country,
                langData: [
                    {
                        twTitle: dataTwTitle,
                        twDescription: dataTwDescription,
                        twImageAlt: dataTwImageAlt,
                        lang: resourceLang,
                        title: dataTitle,
                        metaTitle: dataMetaTitle,
                        metaDescription: dataMetaDescription,
                        content: content,
                        typeName: resourceTypeName,
                        displayType: displayType,
                        paragraphs: paragraphs,
                    },
                ],
            },
        };
        data.resource.country = data.resource.country.map(country => ({
            id: country.value,
            name: country.label,
            shortCode: country.shortCode,
        }));
        console.log(data);
        formData.append("request", JSON.stringify(data));
        formData.append("file", image);
        return formData;
    };

    const prepareImageForPreview = () => {
        return image;
    };

    const onPreviewClicked = e => {
        updatePreviewResourceData(prepareDataForPreview());

        setPreviewImage(image);
        setTimeout(() => {
            isBrowserEnv() && window.open("/resource-preview", "_blank");
        }, 500);
    };

    const onAddParagraphClicked = e => {
        e.preventDefault();
        setParagraphs(prev => [
            ...prev,
            {id: nextId.current++, title: '', content: '', showContent: true},
        ]);
    };
    const onRemoveParagraphClicked = id => {
        setParagraphs(prev => prev.filter(paragraph => paragraph.id !== id));
    };
    const prepareDataForPreview = () => {

        const data = {
            resource: {
                url: dataUrl,
                type: resourceType,
                typeName: resourceTypeName,
                country: country,
                langData: [
                    {
                        twTitle: dataTwTitle,
                        twDescription: dataTwDescription,
                        twImageAlt: dataTwImageAlt,
                        lang: resourceLang,
                        title: dataTitle,
                        metaTitle: dataMetaTitle,
                        metaDescription: dataMetaDescription,
                        content: content,
                        typeName: resourceTypeName,
                        displayType: displayType,
                        paragraphs: paragraphs,
                    },
                ],
            },
        };
        return data;
    };
    const saveExpert = e => {
        e.preventDefault();

        setIsSpinner(true);

        createVectraResourceDetail(prepareData()).then(
            result => {
                setIsSpinner(false);
                //setResult(true);
                if (result.data.code === "1") {
                    MySwal.fire({
                        confirmButtonColor: "#EC726B",
                        title: <strong>Success !</strong>,
                        html: <i>{result.data.description}</i>,
                        icon: "success",
                    });
                } else {
                    MySwal.fire({
                        confirmButtonColor: "#EC726B",
                        title: <strong>Error !</strong>,
                        html: <i>{result.data.description}</i>,
                        icon: "error",
                    });
                }
            },
            error => {
                setIsSpinner(false);
                MySwal.fire({
                    confirmButtonColor: "#EC726B",
                    title: <strong>Error !</strong>,
                    html: <i>{error.data}</i>,
                    icon: "error",
                });
            },
        );
        e.target.reset();
    };

    return (
        <>
            <div className='form-group col-xl-4 mb-8'>
                {/*<Link target="_blank"*/}
                {/*      to={{*/}
                {/*          pathname: "/resource-preview",*/}
                {/*      }}*/}
                {/*>*/}
                {/*    Preview*/}
                {/*    <i className="icon ms-3">*/}
                {/*        {IsSpinner ? <span className="spinner-border spinner-border-sm" role="status"*/}
                {/*                           aria-hidden="true"></span> : <FiArrowRight/>}*/}
                {/*    </i>*/}
                {/*</Link>*/}
                <button className='btn-default btn-small' onClick={onPreviewClicked}>
                    Preview
                    <i className='icon ms-3'>
                        {IsSpinner ? (
                            <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
                        ) : (
                            <FiArrowRight/>
                        )}
                    </i>
                </button>
            </div>
            <form className={`${formStyle} pt-5`} action='' onSubmit={saveExpert}>
                <div className=' row col-lg-12 '>
                    <div className='col-lg-6 '>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='form-label'>Resource Type</label>
                                    {/*<Select pHolder="Resource Type..."*/}
                                    {/*    options={options}*/}
                                    {/*    name="resourceType"*/}
                                    {/*    isMulti={false}*/}
                                    {/*        handleSelectionChange={onChangeResourceType}*/}
                                    {/*></Select>*/}
                                    <Select
                                        name='resource'
                                        options={options}
                                        isMulti={false}
                                        pHolder='Resource Type...'
                                        onValueChange={onChangeResourceType}></Select>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='form-label'>Display Type</label>
                                    {/*<Select pHolder="Resource Type..."*/}
                                    {/*    options={options}*/}
                                    {/*    name="resourceType"*/}
                                    {/*    isMulti={false}*/}
                                    {/*        handleSelectionChange={onChangeResourceType}*/}
                                    {/*></Select>*/}
                                    <Select
                                        name='displayTypeSelect'
                                        options={optionsDisplayType}
                                        isMulti={false}
                                        pHolder='Display Type...'
                                        onValueChange={onChangeDisplayType}></Select>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='form-label'>Language</label>
                                    <Select
                                        name='language'
                                        options={optionsLang}
                                        pHolder='Language..'
                                        isMulti={false}
                                        onValueChange={onChangeResourceLang}></Select>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Country</label>
                            <Select
                                pHolder='Country'
                                options={countryList}
                                name='country'
                                isMulti={true}
                                onValueChange={onChangeCountry}></Select>
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Url</label>
                            <input
                                className='form-control'
                                type='text'
                                name='url'
                                placeholder='url-needs-to-be-unique-and-seo-friendly'
                                onChange={onChangeDataUrl}
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Select Image</label>
                            <input
                                className='form-control'
                                type='file'
                                name='imageFile'
                                placeholder='Image File .png .jpg .jpeg '
                                onChange={onImageChange}
                                required
                            />
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='form-label'>twTitle</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='twTitle'
                                        placeholder='twTitle'
                                        onChange={onChangeDataTwTitle}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='form-label'>twDescription</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='twDescription'
                                        placeholder='twDescription'
                                        onChange={onChangeDataTwDescription}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>twImageAlt</label>
                            <input
                                className='form-control'
                                type='text'
                                name='twImageAlt'
                                placeholder='twImageAlt'
                                onChange={onChangeDataTwImageAlt}
                                required
                            />
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='form-label'>metaTitle</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='metaTitle'
                                        placeholder='metaTitle'
                                        onChange={onChangeDataMetaTitle}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='form-label'>metaDescription</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name='metaDescription'
                                        placeholder='metaDescription'
                                        onChange={onChangeDataMetaDescription}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {displayType == 0 &&
                        <div className='col-lg-6 '>
                            <div className='form-group'>
                                <label className='form-label'>Heading</label>
                                {/*<CkEditor*/}
                                {/*    name="about"*/}
                                {/*></CkEditor>*/}

                                <input
                                    className='form-control'
                                    type='text'
                                    name='title'
                                    placeholder='Heading'
                                    onChange={onChangeDataTitle}
                                    required
                                />
                            </div>

                            <div className='form-group'>
                                <label className='form-label'>Content</label>
                                <CkEditor name='content' editorData={content} onChange={onChangeContent}></CkEditor>
                            </div>
                        </div>
                    }
                    {displayType == 1 &&
                        <div className='col-lg-6 '>
                            <div className='form-group'>
                                <label className='form-label'>Heading</label>
                                {/*<CkEditor*/}
                                {/*    name="about"*/}
                                {/*></CkEditor>*/}

                                <input
                                    className='form-control'
                                    type='text'
                                    name='title'
                                    placeholder='Heading'
                                    onChange={onChangeDataTitle}
                                    required
                                />
                            </div>
                            <div className='col-lg-6 ' onClick={onAddParagraphClicked}>
                                <button className='btn-default btn-small'>
                                    Add Contextual Paragraph
                                    <i className='icon ms-3'>
                                        {IsSpinner ? (
                                            <span className='spinner-border spinner-border-sm' role='status'
                                                  aria-hidden='true'></span>
                                        ) : (
                                            <FiArrowRight/>
                                        )}
                                    </i>
                                </button>
                            </div>
                            {
                                paragraphs.map((paragraph, index) => (
                                    <div key={paragraph.id}>
                                        <button className='btn btn-danger'
                                                onClick={() => onRemoveParagraphClicked(paragraph.id)}>Delete
                                        </button>
                                        <div className='form-group'>
                                            <label className='form-label'>Paragraph Heading</label>
                                            <input
                                                className='form-control'
                                                type='text'
                                                name={`title-${paragraph.id}`}
                                                placeholder='Heading'
                                                value={paragraph.title}
                                                onChange={e => {
                                                    const newTitle = e.target.value;
                                                    setParagraphs(current =>
                                                        current.map(p => {
                                                            if (p.id === paragraph.id) {
                                                                return {...p, title: newTitle};
                                                            }
                                                            return p;
                                                        })
                                                    );
                                                }}
                                                required
                                            />

                                            <div className='form-group'>
                                                <input type='checkbox'
                                                       checked={paragraph.showContent} onChange={e => {
                                                    const newShowContent = e.target.checked;
                                                    setParagraphs(current =>
                                                        current.map(p => {
                                                            if (p.id === paragraph.id) {
                                                                return {...p, showContent: newShowContent};
                                                            }
                                                            return p;
                                                        })
                                                    );
                                                }}></input>
                                                <label htmlFor='checkbox'>Show content on paragraph</label>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label className='form-label'>Content</label>
                                            <CkEditor name={`content-${paragraph.id}`} editorData={paragraph.content}
                                                      onChange={data => {
                                                          setParagraphs(current =>
                                                              current.map(p => {
                                                                  if (p.id === paragraph.id) {
                                                                      return {...p, content: data};
                                                                  }
                                                                  return p;
                                                              })
                                                          );
                                                      }}></CkEditor>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    <div className='row ps-5 pb-5'>
                        <div className='form-group  col-xl-4'>
                            <button className='btn-default btn-small'>
                                Save
                                <i className='icon ms-3'>
                                    {IsSpinner ? (
                                        <span className='spinner-border spinner-border-sm' role='status'
                                              aria-hidden='true'></span>
                                    ) : (
                                        <FiArrowRight/>
                                    )}
                                </i>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default AddResource;