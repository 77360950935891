import OpenPositionTable from "./OpenPositionTable";

function ManageOpenPosition() {
  return (
    <>
      <div className='row col-lg-10'>
        <OpenPositionTable></OpenPositionTable>
      </div>
    </>
  );
}

export default ManageOpenPosition;
