import React, { Suspense, useEffect } from "react";
import AppRoutes from "./Routes/AppRoutes.jsx";
import LoadingSpinner from "./components/spinner/LoadingSpinner";
import "./assets/scss/style.scss";

function App() {
  return (
    <>
      <Suspense fallback={<LoadingSpinner />}>
        <AppRoutes></AppRoutes>
      </Suspense>
    </>
  );
}
export default App;
