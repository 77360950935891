import isBrowserEnv from "../utils/isBrowserEnv";

export const getPreviewResourceData = () => {
  if (!isBrowserEnv()) return null;
  const resourceData = sessionStorage.getItem("previewResourceData");
  return resourceData ? JSON.parse(resourceData) : null;
};

export const setPreviewResourceData = data => {
  isBrowserEnv() && sessionStorage.setItem("previewResourceData", JSON.stringify(data));
};

export const clearPreviewResourceData = () => {
  isBrowserEnv() && sessionStorage.removeItem("previewResourceData");
  isBrowserEnv() && sessionStorage.removeItem("previewResourceImage");
};

export const setPreviewImage = async file => {
  if (!file) {
    // todo: No File Provided
    return;
  }

  try {
    const base64String = await fileToBase64(file);
    isBrowserEnv() && sessionStorage.setItem("previewResourceImage", base64String);
  } catch (error) {
    // TODO: Handle Error ( Error Storing Files ( Images))
  }
};

const fileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const buffer = reader.result;
      const base64String = arrayBufferToBase64(buffer);
      resolve(base64String);
    };
    reader.onerror = error => {
      // Error reading file
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};
const arrayBufferToBase64 = buffer => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  const base64String = btoa(binary);
  return base64String;
};
export const getPreviewImageData = () => {
  const base64String = isBrowserEnv() && sessionStorage.getItem("previewResourceImage");
  return base64String ? base64ToBlob(base64String) : null;
};

const base64ToBlob = (base64String, contentType = "") => {
  if (!base64String) {
    return null;
  }

  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};
