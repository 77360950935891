import PartnersTable from "./PartnersTable";

function ManagePartners() {
  return (
    <>
      <div className='row col-lg-10'>
        <PartnersTable></PartnersTable>
      </div>
    </>
  );
}

export default ManagePartners;
