import { useState } from "react";
import { regex } from "./LoginForm";
import { Alert } from "reactstrap";

function ForgotpassForm() {
  const [email, setEmail] = useState(null);

  const [passwordResetFailed, setPasswordResetFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onForgotPassword = e => {
    e.preventDefault();
    if (!regex.test(email.trim())) {
      setPasswordResetFailed(true);
      setErrorMessage("You are not a member of Vectra-Intl. You can't reset password.");
      return;
    } else {
      setPasswordResetFailed(false);
      setErrorMessage("");
    }

    // USER IS VECTRA'S MEMBER LET THEM RESET PASSWORD
    // TODO

    //
    e.target.reset();
  };
  return (
    <>
      {passwordResetFailed && (
        <Alert
          color='danger'
          style={{
            background: "transparent",
            margin: "2rem 0",
            border: "none",
            borderLeft: "2px solid salmon",
            borderRadius: 0,
            color: "salmon",
          }}>
          {errorMessage}
        </Alert>
      )}
      <div className='mt-5 '>
        <div className='container'>
          <div className='row row--40 align-items-center'>
            <form className={``} action='' onSubmit={onForgotPassword}>
              <div className='col-lg-12'>
                <div className='form-group'>
                  <input
                    type='text'
                    name='loginEmail'
                    placeholder='Email Adress'
                    required
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className='col-lg-12 mt-5'>
                <div className='form-group'>
                  <button className='btn-default  btn-large'>Send</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotpassForm;
