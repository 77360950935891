import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { FiArrowRight } from "react-icons/fi";
import Select from "./Select"; // Assuming this is a custom Select component wrapping react-select
import CkEditor from "../../components/Editor/CkEditor";
import JobLocationList from "../../../../data/search/jobLocation.json";
import JobTypeList from "../../../../data/search/jobType.json";
import JobCategoryList from "../../../../data/search/jobCategory.json";
import JobCountryList from "../../../../data/search/country.json";
import { updateOpenPosition } from "../../../../service/publicServices";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

function EditModal(props) {
  const [IsSpinner, setIsSpinner] = useState(false);
  const [title, setTitle] = useState("");
  const [jobLocation, setJobLocation] = useState(null);
  const [jobType, setJobType] = useState(null);
  const [jobCountry, setJobCountry] = useState(null);
  const [jobCategory, setJobCategory] = useState(null);
  const [jobBody, setJobBody] = useState("");
  const [openPositionId, setOpenPositionId] = useState(null);

  useEffect(() => {
    if (props.modaldata && props.modaldata.original) {
      const original = props.modaldata.original;
      setOpenPositionId(original.id);
      setTitle(original.title || "");
      setJobLocation(original.workLocation ? { value: original.workLocation.value, label: original.workLocation.label } : null);
      setJobType(original.jobType ? { value: original.jobType.value, label: original.jobType.label } : null);
      setJobCountry(original.jobCountry ? { value: original.jobCountry.value, label: original.jobCountry.label } : null);
      setJobCategory(original.category ? { value: original.category.value, label: original.category.label } : null);
      setJobBody(original.body || "");
    }
  }, [props.modaldata]);

  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const onChangeJobLocation = (selectedOption) => {
    setJobLocation(selectedOption);
  };

  const onChangeJobType = (selectedOption) => {
    setJobType(selectedOption);
  };

  const onChangeJobCountry = (selectedOption) => {
    setJobCountry(selectedOption);
  };

  const onChangeJobCategory = (selectedOption) => {
    setJobCategory(selectedOption);
  };

  const onChangeJobBody = (data) => {
    setJobBody(data);
  };

  const prepareData = () => {
    return {
      id: openPositionId,
      category: jobCategory,
      date: new Date(),
      jobCountry: jobCountry,
      jobType: jobType,
      workLocation: jobLocation,
      title: title,
      body: jobBody,
    };
  };

  const saveChanges = (e) => {
    e.preventDefault();
    setIsSpinner(true);
    const data = prepareData();

    updateOpenPosition(data).then(
        (result) => {
          setIsSpinner(false);
          if (result.data.code === "1") {
            MySwal.fire({
              confirmButtonColor: "#EC726B",
              title: <strong>Success !</strong>,
              html: <i>{result.data.description}</i>,
              icon: "success",
            }).then(() => {
              props.onSave(); // Call the onSave callback after successful save
            });
          } else {
            MySwal.fire({
              confirmButtonColor: "#EC726B",
              title: <strong>Error !</strong>,
              html: <i>{result.data.description}</i>,
              icon: "error",
            });
          }
        },
        (error) => {
          setIsSpinner(false);
          MySwal.fire({
            confirmButtonColor: "#EC726B",
            title: <strong>Error !</strong>,
            html: <i>{error.data}</i>,
            icon: "error",
          });
        }
    );
  };

  return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="modal-custom-admin">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="modal-subtitle">Edit: {title}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="pt-3" action="" onSubmit={saveChanges}>
            <div className="form-group">
              <label className="form-label">Title</label>
              <input type="text" name="title" placeholder="Title" required onChange={onChangeTitle} value={title} />
            </div>
            <div className="form-group">
              <label className="form-label">Body</label>
              <CkEditor name="about" editorData={jobBody} onChange={onChangeJobBody}></CkEditor>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="form-label">Job Location</label>
                  <Select
                      pHolder="Job Location"
                      options={JobLocationList}
                      name="jobLocation"
                      selectedvalue={jobLocation}
                      isMulti={false}
                      onValueChange={onChangeJobLocation}
                  ></Select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="form-label">Job Type</label>
                  <Select
                      pHolder="Job Type"
                      options={JobTypeList}
                      name="jobType"
                      selectedvalue={jobType}
                      isMulti={false}
                      onValueChange={onChangeJobType}
                  ></Select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="form-label">Job Country</label>
                  <Select
                      pHolder="Job Country"
                      options={JobCountryList}
                      name="jobCountry"
                      selectedvalue={jobCountry}
                      isMulti={false}
                      onValueChange={onChangeJobCountry}
                  ></Select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="form-label">Job Category</label>
                  <Select
                      pHolder="Job Category"
                      options={JobCategoryList}
                      name="jobCategory"
                      selectedvalue={jobCategory}
                      isMulti={false}
                      onValueChange={onChangeJobCategory}
                  ></Select>
                </div>
              </div>
            </div>
            <div className="row ps-5">
              <div className="form-group col-xl-6">
                <button className="btn-default btn-small">
                  Save
                  <i className="icon ms-3">
                    {IsSpinner ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    ) : (
                        <FiArrowRight />
                    )}
                  </i>
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
  );
}

export default EditModal;
