import { useState, useMemo } from "react";
import EditModal from "./EditModal";
import DataTable from "../../components/DataTable/DataTable";
import CarouselList from "../../../../data/carousels/carousels.json";

function CarouselsTable() {
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Banner Heading",
        accessor: "name",
      },
      {
        Header: "Banner Description",
        accessor: "heading",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Image",
        accessor: "imageUrl",
      },
      {
        Header: "Status",
        accessor: "carouselsStatus",
        Cell: ({ value }) => (
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              id='flexSwitchCheckChecked'
              defaultChecked={value}
              disabled
            />
          </div>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    [],
  );
  const [showCarouselEditModal, setShowCarouselEditModal] = useState(false);
  const [rowData, setRowData] = useState(null);

  return (
    <>
      <div className='row col-lg-12'>
        <DataTable
          data={CarouselList}
          columns={columns}
          PageSize={5}
          sortable={true}
          setRowData={setRowData}
          setshowEditModal={setShowCarouselEditModal}
        />
      </div>
      <EditModal show={showCarouselEditModal} modaldata={rowData} onHide={() => setShowCarouselEditModal(false)}></EditModal>
    </>
  );
}

export default CarouselsTable;
