// langNames.js
const langNames = {
    en: "English",
    tr: "Türkçe",
    de: "Deutsch",
    bd: "Bangla",
    hi: "Hindi",
    cm: "Mandarin",
    pt: "Portuguese"
};

export default langNames;