import {useState} from "react";
import {FiArrowRight} from "react-icons/fi";

import {
    createClient
} from "../../../../service/publicServices";


function AddClient() {
    const [file, setFile] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [partnerName, setPartnerName] = useState("")
    const [url, setUrl] = useState("")
    const [order, setOrder] = useState("")


    const handleNameChange = (val) => {
        setPartnerName(val);
    };


    const handleUrlChange = (val) => {
        setUrl(val);
    };


    const handleOrderChange = (val) => {
        setOrder(val);
    }

    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            setFile(file);
            setImagePreviewUrl(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handleSubmit = async () => {
        const data = new FormData();
        if (file) {
            data.append('file', file);
        }
        data.append('request', JSON.stringify({

            order: Number(order),
            url: url,
            name: partnerName

        }));

        try {
            const response = await createClient(data);
            alert(`Response: ${response.data.description}`);
        } catch (error) {
            alert(`Error: ${error.response.data.description || 'Something went wrong'}`);
        }
    };

    return (
        <div>
            <h1>Add Client</h1>
            <div className="row">
                <div className="col-6">
                    <div>
                        <label>Client Name:</label>
                        <input
                            type="text"
                            value={partnerName}
                            onChange={(e) => handleNameChange(e.target.value)}
                        />
                        <label>Order:</label>
                        <input
                            type="number"
                            value={order}
                            onChange={(e) => handleOrderChange(e.target.value)}
                        />
                        <button onClick={() => handleSubmit()}>Submit</button>
                    </div>

                </div>
                <div className="col-6">
                    <input type="file" onChange={handleImageChange}/>
                    {imagePreviewUrl && (
                        <div>
                            <h2>Preview:</h2>
                            <img src={imagePreviewUrl} alt="Carousel Preview" style={{width: '500px', height: 'auto'}}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

}


export default AddClient