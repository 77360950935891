import axios from "axios";
import store from "../store";
import { refreshToken } from "../actions/auth";

const { dispatch } = store;
const instance = axios.create({

baseURL: "https://vectra-international-core.azurewebsites.net",


});

instance.interceptors.request.use(
  (config) => {
    const token = store.getState().auth?.user?.accessToken;
    //debugger;
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "vectra/auth/signin" && err.response) {
      // Access Token was expired
      if ((err.response.status === 403 || err.response.status === 401 )&& !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await axios.post("https://vectra-international-core.azurewebsites.net/vectra/auth/refreshtoken", {
            refreshToken: store.getState().auth?.user.refreshToken,
          });
          const { accessToken } = rs.data;
          dispatch(refreshToken(accessToken));
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default instance;