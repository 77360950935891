import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../../pages/Admin/components/Header/Header";
import Sidebar from "../../pages/Admin/components/sideNavBar/SideNavBar";
import Copyright from "../footer/CopyrightAdmin";

export default function LayoutAdmin() {
  const navigate = useNavigate();
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  useEffect(_ => {
    const user = localStorage.getItem("user");

    // NO USER IN LOCAL STRORAGE , REROUTE TO LOGIN PAGE.
    if (!JSON.parse(user)) {
      navigate("/login");
      return;
    }

    // AUTHENTICATE THE USER HERE

    setUserAuthenticated(true);
  }, []);

  if (!userAuthenticated) return <AuthenticatingUser />;
  return (
    <div className='app'>
      {/*  <Header HeaderSTyle="header-admin-default "></Header> */}
      <Header></Header>
      <div className='main-wrapper'>
        <Sidebar></Sidebar>
        <div className='main-content'>
          <Outlet></Outlet>
        </div>
        <Copyright />
      </div>
    </div>
  );
}

// LOADING STATE WHEN A USER IS BEING AUTHENTICATED.
function AuthenticatingUser() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        fontWeight: "bold",
        color: "black",
      }}>
      <span style={{ fontWeight: "normal", fontSize: "1rem" }}>Wait ...</span>
      <span>Authenticating User</span>
    </div>
  );
}
