import { useState, useEffect, useCallback, useMemo } from "react";
import EditModal from "./EditModal";
import EditSubModal from "./EditSubModal";
import AddLangModal from "./AddLangModal";
import DataTable from "../../components/DataTable/DataTable";
import RowSubComponent from "../../components/TableRowSubComponent/RowSubComponent ";
import { getResourceList, deleteVectraResource } from "../../../../service/publicServices";

// TODO: REFACTOR

function ResourceTable() {
  const [resourceList, setResourceList] = useState([]);
  const [showResourceEditModal, setshowResourceEditModal] = useState(false);
  const [showResourceEditChildModal, setshowResourceEditChildModal] = useState(false);
  const [showResourceAddLangdModal, setshowResourceAddLangdModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [rowChildData, setRowChildData] = useState(null);
  const [rowUrl, setRowUrl] = useState(null);
  const [rowLangType, setrowLangType] = useState(null);
  const [rowCount, setrowCount] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);

  const totalPages = Math.ceil(rowCount / 10);

  const prepareData = useCallback(() => {
    return {
      page: pageIndex + 1,
      lang: "en",
    };
  }, [pageIndex]);

  const fetchData = useCallback(async () => {
    try {
      const data = await getResourceList(prepareData());
      if (data?.data?.resources) {
        data.data.resources.forEach(function (obj) {
          obj.typeName = obj.langData[0].typeName;
          obj.title = obj.langData[0].title;
        });
        setResourceList(data.data.resources);
        setrowCount(data.data.rowCount || 0);
      } else {
        
      }
    } catch (error) {
     
    }
  }, [prepareData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {}, [resourceList]);

  

  const handlePageChange = newPageIndex => {
    const clampedPageIndex = Math.max(0, Math.min(newPageIndex, totalPages - 1));
    setPageIndex(clampedPageIndex);
  };
  const updateResourceList = (url, lang, title) => {
    setResourceList(prevResourceList =>
      prevResourceList.map(item => {
        if (item.url === url) {
          const updatedLangData = item.langData.map(langDataItem => {
            if (langDataItem.lang === lang) {
              return { ...langDataItem, title: title };
            }
            return langDataItem;
          });
          return { ...item, langData: updatedLangData };
        }
        return item;
      }),
    );
  };

    const deleteRow = row => {
    const delJson = { url: row?.original?.url };
    const formData = new FormData();
    formData.append("request", JSON.stringify(delJson));

    deleteVectraResource(formData)
      .then(response => {})
      .catch(error => {});
  }; 
  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>{isAllRowsExpanded ? "🔽" : "▶️"}</span>
        ),
        Cell: ({ row }) => <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? "🔽" : "▶️"}</span>,
      },
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Görsel",
        accessor: "imgUrl",
        sortable: false,
      },
      {
        Header: "Tür Adı",
        accessor: "typeName",
        sortable: false,
      },
      {
        Header: "Başlık",
        accessor: "title",
        sortable: true,
      },
      {
        Header: "İşlemler",
        accessor: "actions",
        sortable: false,
        minWidth: 500,
      },
    ],
    [],
  );
/*   const renderRowSubComponent = useCallback(
    ({ row }) => (
      <table className='table'>
        <thead>
          <tr>
            <th>Language</th>
            <th>Heading</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody id='deger-tablosu'>
          <tr>
            <td>en</td>
            <td>
              {row.original.langData?.filter(obj => obj.lang === "en")[0] ? (
                row.original.langData?.filter(obj => obj.lang === "en")[0].title
              ) : (
                <button className='btn btn-primary btn-lg' onClick={() => addLand("en", row.original.url)}>
                  Add Lang
                </button>
              )}
            </td>
            <td>
              {row.original.langData?.filter(obj => obj.lang === "en")[0] ? (
                <button
                  className='btn btn-warning btn-lg '
                  onClick={() =>
                    editChildRow(row.original.langData?.filter(obj => obj.lang === "en")[0], row.original.url)
                  }>
                  Edit
                </button>
              ) : (
                ""
              )}
            </td>
          </tr>
          <tr>
            <td>tr</td>
            <td>
              {row.original.langData?.filter(obj => obj.lang === "tr")[0] ? (
                row.original.langData?.filter(obj => obj.lang === "tr")[0].title
              ) : (
                <button className='btn btn-primary btn-lg' onClick={() => addLand("tr", row.original.url)}>
                  Add Lang
                </button>
              )}
            </td>
            <td>
              {row.original.langData?.filter(obj => obj.lang === "tr")[0] ? (
                <button
                  className='btn btn-warning btn-lg '
                  onClick={() =>
                    editChildRow(row.original.langData?.filter(obj => obj.lang === "tr")[0], row.original.url)
                  }>
                  Edit
                </button>
              ) : (
                ""
              )}
            </td>
          </tr>
          <tr>
            <td>de</td>
            <td>
              {row.original.langData?.filter(obj => obj.lang === "de")[0] ? (
                row.original.langData?.filter(obj => obj.lang === "de")[0].title
              ) : (
                <button className='btn btn-primary btn-lg' onClick={() => addLand("de", row.original.url)}>
                  Add Lang
                </button>
              )}
            </td>
            <td>
              {row.original.langData?.filter(obj => obj.lang === "de")[0] ? (
                <button
                  className='btn btn-warning btn-lg '
                  onClick={() =>
                    editChildRow(row.original.langData?.filter(obj => obj.lang === "de")[0], row.original.url)
                  }>
                  Edit
                </button>
              ) : (
                ""
              )}
            </td>
          </tr>
          <tr>
            <td>bd</td>
            <td>
              {row.original.langData?.filter(obj => obj.lang === "bd")[0] ? (
                row.original.langData?.filter(obj => obj.lang === "bd")[0].title
              ) : (
                <button className='btn btn-primary btn-lg' onClick={() => addLand("bd", row.original.url)}>
                  Add Lang
                </button>
              )}
            </td>
            <td>
              {row.original.langData?.filter(obj => obj.lang === "bd")[0] ? (
                <button
                  className='btn btn-warning btn-lg'
                  onClick={() =>
                    editChildRow(row.original.langData?.filter(obj => obj.lang === "bd")[0], row.original.url)
                  }>
                  Edit
                </button>
              ) : (
                ""
              )}
            </td>
          </tr>
        </tbody>
      </table>
    ),
    [],
  ); */

  const editChildRow = useCallback((row, rowUrl) => {
    setRowChildData(row);
    setRowUrl(rowUrl);
    setshowResourceEditChildModal(true);
  }, []);
  
  const addLand = useCallback((langType, rowUrl) => {
    setrowLangType(langType);
    setRowUrl(rowUrl);
    setshowResourceAddLangdModal(true);
  }, []); 
  
  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <RowSubComponent
        row={row}
        addLand={addLand}
        editChildRow={editChildRow}
      />
    ),
    [addLand, editChildRow]
  );
 
  return (
    <>
      <div className='row col-lg-12'>
        {resourceList && (
          <DataTable
            data={resourceList}
            columns={columns}
            sortable={true}
            setRowData={setRowData}
            setshowEditModal={setshowResourceEditModal}
            renderRowSubComponent={renderRowSubComponent}
            pageIndex={pageIndex}
            onPageChange={handlePageChange}
            totalPages={totalPages}
            deleteRow={deleteRow}
          />
        )}
      </div>
      <EditModal
        show={showResourceEditModal}
        modaldata={rowData}
        onHide={() => {
          setshowResourceEditModal(false);
          if (rowData) {
            const { url, lang, title } = rowData;
            updateResourceList(url, lang, title);
          }
          fetchData();
        }}></EditModal>
      <EditSubModal
        show={showResourceEditChildModal}
        modaldata={rowChildData}
        onHide={() => {
          setshowResourceEditChildModal(false);
          if (rowData) {
            const { url, lang, title } = rowData;
            updateResourceList(url, lang, title);
          }
          fetchData();
        }}
        rowurl={rowUrl}></EditSubModal>
      <AddLangModal
        show={showResourceAddLangdModal}
        modaldata={rowChildData}
        onHide={() => {
          setshowResourceAddLangdModal(false);
          fetchData();
        }}
        rowurl={rowUrl}
        langtype={rowLangType}></AddLangModal>
    </>
  );
}

export default ResourceTable;
