// Create a new file, e.g., RowSubComponent.js

import React from "react";

const RowSubComponent = ({ row, addLand, editChildRow }) => {

    const langCodes = ["en", "tr", "de", "bd","hi","cm","pt"];

    const langNames = {
        en: "English",
        tr: "Türkçe",
        de: "Deutsch",
        bd: "Bangla",
        hi: "Hindi",
        cm: "Mandarin",
        pt : "Portuguese"
      };
    return (
        <table className='table'>
            <thead>
                <tr>
                    <th>Language</th>
                    <th>Heading</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody id='deger-tablosu'>
                {langCodes.map(langCode => (
                    <tr key={langCode}>
                        <td>{langNames[langCode]}</td>
                        <td>
                            {row.original.langData?.filter(obj => obj.lang === langCode)[0] ? (
                                row.original.langData?.filter(obj => obj.lang === langCode)[0].title
                            ) : (
                                <button
                                    className='btn btn-primary btn-lg'
                                    onClick={() => addLand(langCode, row.original.url)}>
                                    Add Lang
                                </button>
                            )}
                        </td>
                        <td>
                            {row.original.langData?.filter(obj => obj.lang === langCode)[0] ? (
                                <button
                                    className='btn btn-warning btn-lg'
                                    onClick={() =>
                                        editChildRow(row.original.langData?.filter(obj => obj.lang === langCode)[0], row.original.url)
                                    }>
                                    Edit
                                </button>
                            ) : (
                                ""
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default RowSubComponent;
