import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../../../actions/auth";
import { updateSessionData } from "../../../../actions/sessionAction";
import { Alert } from "reactstrap";

//todo: PLEASE ADD OTHER DOMAINS OF VECTRA INT'L BRANDS HERE.
// THIS PATTERN CHECKS IF THE USER IS PART OF VECTRA-INTERNATIONAL, FOR EXAMPLE: name@vectra-intl.com, THIS EMAIL IS VALID AND THE USER IS VECTRA INT'L MEMBER.
export const regex = /[a-z0-9_-]+@(vectra-intl\.com|fft\.com)$/i;

function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // state

  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [authenticated, setauthenticated] = useState(
    localStorage.getItem(localStorage.getItem("authenticated") || false),
  );

  const onLogin = e => {
    e.preventDefault();
    if (!regex.test(email.trim())) {
      setIsLoginFailed(true);
      setErrorMessage(`Sorry, you are not a member of Vectra-Intl.`);
      return;
    } else {
      setIsLoginFailed(false);
      setErrorMessage("");
    }

    setLoading(true);

    dispatch(login(email, password))
      .then(_ => {
        //UPDATE SUCCESSFULLY LOGIN UPDATE SESSION DATA.
        navigate("/");

        // log in success
        isLoginFailed(false);
        setErrorMessage("");
      })
      .catch(err => {
        setLoading(false);
        setErrorMessage("Error: " + err);
        setIsLoginFailed(true);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("user")) {
      // USER DATA SEEMS TO EXIST , NOW AUTHENTICATE IF THE USER'S DATA ARE RIGHT.
      // AFTER THAT ROUTE THE USER TO ADMIN PAGE.

      // TODO;

      navigate("/");
      return;
    }
  });

  return (
    <>
      {isLoginFailed && (
        <Alert
          color='danger'
          style={{
            background: "transparent",
            margin: "2rem 0",
            border: "none",
            borderLeft: "2px solid salmon",
            borderRadius: 0,
            color: "salmon",
          }}>
          {errorMessage}
        </Alert>
      )}
      <div className='mt-5 '>
        <div className='container'>
          <div className='row row--40 align-items-center'>
            <form className={``} action='' onSubmit={onLogin}>
              <div className='col-lg-12'>
                <div className='form-group'>
                  <input
                    type='text'
                    name='loginEmail'
                    placeholder='Email Adress'
                    required
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className='col-lg-12'>
                <div className='form-group'>
                  <input
                    type='password'
                    name='loginPassword'
                    placeholder='Password'
                    required
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className='col-lg-12 text-end'>
                <div className='form-group'>
                  <input
                    type='checkbox'
                    id='checkbox'
                    checked={isRememberMeChecked}
                    onChange={_ => setIsRememberMeChecked(!isRememberMeChecked)}></input>
                  <label htmlFor='checkbox'>Remember Me</label>
                </div>
              </div>
              <div className='col-lg-12 mt-5'>
                <div className='form-group'>
                  <button className='btn-default btn-large'>Login</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginForm;
