// TestimonialsManager.jsx
import React, { useState, useEffect } from 'react';
import TestimonialForm from './TestimonialForm';
import { createUpdateTestimonial, deleteTestimonial, fetchTestimonials } from "../../../../service/publicServices";
import TestimonialList from "./TestimonialsList";

const TestimonialsManager = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [editingTestimonial, setEditingTestimonial] = useState(null);
    const [editingLang, setEditingLang] = useState(null)

    const [actionAdd, setActionAdd] = useState(false);
    const [actionEdit, setActionEdit] = useState(false);
    const [actionAddLang, setActionAddLang] = useState(false);

    useEffect(() => {
        loadTestimonials();
    }, []);

    const loadTestimonials = () => {
        fetchTestimonials().then(response => {
            if (response.data.testimonials) {

                setTestimonials(response.data.testimonials);
            } else {
                setTestimonials([]);
            }
        }).catch(error => {
            console.error('Error fetching testimonials', error);
        });
    };

    const addOrEditTestimonial = (testimonialData) => {

        createUpdateTestimonial(testimonialData).then(() => {
            loadTestimonials();
            setEditingTestimonial(null); // Reset editing after submit
        });
    };

    const startEditing = (testimonial, eLang) => {

        setEditingLang(eLang)
        setActionEdit(true);
        setActionAdd(false)
        setActionAddLang(false)
        setEditingTestimonial(testimonial); // Set the testimonial to be edited
    };

    const startAddingNew = () => {
        setEditingLang(null)
        setActionAdd(true)
        setActionAddLang(false)
        setActionEdit(false)
        setEditingTestimonial({ langData: [] }); // Empty object for new testimonial
    };

    const cancelEditing = () => {
        setEditingLang(null)
        setActionAdd(false)
        setActionAddLang(false)
        setActionEdit(false)
        setEditingTestimonial(null); // Clear editing testimonial
    };

    const removeTestimonial = (testimonialId) => {
        deleteTestimonial({ testimonialId: testimonialId }).then(() => {
            loadTestimonials();
        });
    };


    const startAddingLanguage = (testimonial, langCode) => {
        // This would prepare the form for adding a new language to an existing testimonial.
        const newLangData = {
            id: testimonial.id,
            lang: langCode, // Language code for the new language
            text: '',
            title: '',
            unit: '',
            order: testimonial.order // Maintain the same order by default
        };
        setEditingLang(langCode)
        setActionAdd(false)
        setActionAddLang(true)
        setActionEdit(false)
        // Clone the testimonial and add newLangData to its langData array
        setEditingTestimonial(newLangData);
    };


    return (
        <div className='row'>
            <div className='col-3'>
              <button className='btn btn-warning' onClick={startAddingNew}>Add New Testimonial</button>
                {(editingTestimonial || editingTestimonial === {}) && (
                    <TestimonialForm
                        testimonial={editingTestimonial}
                        onSave={addOrEditTestimonial}
                        onCancel={cancelEditing}
                        editingLang={editingLang}
                        actionAdd={actionAdd}
                        actionEdit={actionEdit}
                        actionAddLang={actionAddLang}
                    />
                )}
            </div>
            <div className='col-9'>
            <TestimonialList
                testimonials={testimonials}
                onEdit={startEditing}
                onDelete={removeTestimonial}
                startAddingLanguage={startAddingLanguage}
            />
            </div>

        </div>
    );
};

export default TestimonialsManager;