import { Routes, Route } from "react-router-dom";
import LayoutAdmin from "../components/layout/LayoutAmin";
import Error from "../pages/Error";
import Login from "../pages/Admin/pages/login/Login";
import AdminHome from "../pages/Admin/pages/Home/AdminHome";
import AdminCarousels from "../pages/Admin/pages/Carousels/AdminCarousels";
import AdminExperts from "../pages/Admin/pages/Experts/AdminExperts";
import AdminManageAccess from "../pages/Admin/pages/ManageAccess/AdminManageAccess";
import AdminPartners from "../pages/Admin/pages/Partners/AdminPartners";
import AdminServices from "../pages/Admin/pages/Services/AdminServices";
import AdminSettings from "../pages/Admin/pages/Settings/AdminSettings";
import AdminTestimonials from "../pages/Admin/pages/Testimonials/AdminTestimonials";
import AdminResource from "../pages/Admin/pages/Resource/AdminResource";
import AdminOpenPosition from "../pages/Admin/pages/OpenPosition/AdminOpenPosition";

import "../configs/i18n";
import "../utils/cookies.jsx";
import ReactGA from "react-ga4";
import AdminClients from "../pages/Admin/pages/Clients/AdminClients";
const TRACKING_ID = "G-TZZ60MDCKS"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const AppRoutes = () => {
  return (
    <Routes>
      <Route exact path='/' element={<LayoutAdmin />}>
        <Route index element={<AdminHome />}></Route>
        <Route path='carousel' element={<AdminCarousels />}></Route>
        <Route path='experts' element={<AdminExperts />}></Route>
        <Route path='manage-access' element={<AdminManageAccess />}></Route>
        <Route path='partners' element={<AdminPartners />}></Route>
          <Route path='clients' element={<AdminClients />}></Route>
        <Route path='services' element={<AdminServices />}></Route>
        <Route path='settings' element={<AdminSettings />}></Route>
        <Route path='testimonials' element={<AdminTestimonials />}></Route>
        <Route path='resource' element={<AdminResource />}></Route>
        <Route path='open-positions' element={<AdminOpenPosition />}></Route>
          <Route path='main-page-slider'></Route>
      </Route>
      <Route path='/login' element={<Login />}></Route>
      <Route path='*' element={<Error />}></Route>
    </Routes>
  );
};

export default AppRoutes;